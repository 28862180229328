import React from "react";
import Zoom from "react-medium-image-zoom";
import { getPropertyImageUrl } from "../../utils/image.utils"; // Adjust the import path as necessary

interface SmallImagesProps {
  images: any[];
  propertyId: string;
  onImageSelect: (image: any) => void;
  selectedImage: any;
  containerRef: React.RefObject<HTMLDivElement>;
}

const SmallImages: React.FC<SmallImagesProps> = ({
  images,
  propertyId,
  onImageSelect,
  selectedImage,
  containerRef,
}) => {
  return (
    <div
      className="hidden lg:flex gap-4 w-full overflow-x-auto overflow-y-hidden scroll-smooth"
      ref={containerRef}
    >
      {images.map((image) => {
        if (!image || !image.id) return null;

        return (
          <img
            key={image?.id}
            className={`w-96 h-32 object-cover rounded-lg cursor-pointer  small-image ${
              selectedImage && selectedImage.id === image.id
                ? "border-4 border-[#14223d]"
                : ""
            }`}
            src={getPropertyImageUrl(propertyId, image)}
            alt="Property"
            onClick={() => onImageSelect(image)}
          />
        );
      })}
    </div>
  );
};

export default SmallImages;
