import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import useMediaQuery from "@mui/material/useMediaQuery";
import { format, set } from "date-fns";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Currency, User, UserStatus } from "../../__generated__/graphql";
import VerificationImg from "../../assets/svgs/7030148_security_locked_ui basic_lock_protection_icon.svg";
import { GlobalErrorHandler } from "../../components/error/global_error.component";
import { Modal } from "../../components/modal/modal";
import { PaymentMethod } from "../../components/payment_method/payment_method";
import { Sidebar } from "../../components/sidebar/sidebar";
import TransactionsSkeleton from "../../components/skeleton/transactionskeleton";
import VaultSkeleton from "../../components/skeleton/vaultskeleton";
import { DEPOSIT, DEPOSIT_CRYPTO } from "../../graphql/mutations/deposit";
import { LIST_PAYMENT_METHODS } from "../../graphql/mutations/list_payment_methods";
import { GET_BALANCE } from "../../graphql/queries/get-balance";
import { QUERY_GET_CURRENCIES } from "../../graphql/queries/get-currency";
import { QUERY_GET_ME } from "../../graphql/queries/get-me";
import { QUERY_GET_SUMSUB_WEB_SDK_LINK } from "../../graphql/queries/get-sumsub-link";
import { GET_TRANSACTIONS } from "../../graphql/queries/get-transactions";
import { toCurrencyDisplay } from "../../utils/currency.util";
import { useAuthAndErrorHandling } from "../../utils/invalid-token.util";
import ImportantNoticeImg from "../../assets/svgs/shield-waring.svg";
const useResponsiveSize = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  return {
    width: isSmallScreen ? 300 : 700,
    height: isSmallScreen ? 275 : 550,
  };
};

export function VaultPage() {
  const responsiveSize = useResponsiveSize();
  const navigate = useNavigate();
  const [iframeUrl, setIframeUrl] = useState<string>("");

  const {
    data: user,
    loading,
    error,
  } = useQuery<{ me: User }>(QUERY_GET_ME, { fetchPolicy: "network-only" });
  const [
    listPaymentMethods,
    {
      loading: loadingPaymentMethods,
      data: paymentMethodData,
      error: paymentMethodError,
    },
  ] = useLazyQuery(LIST_PAYMENT_METHODS, { fetchPolicy: "network-only" });
  const [
    getTransactions,
    {
      loading: loadingTransactions,
      data: transactionData,
      error: transactionError,
    },
  ] = useLazyQuery(GET_TRANSACTIONS, { fetchPolicy: "network-only" });
  const [
    getBalance,
    { loading: loadingBalance, data: balanceData, error: getBalanceError },
  ] = useLazyQuery(GET_BALANCE, { fetchPolicy: "network-only" });
  const [
    getcurrencies,
    {
      loading: loadingCurrencies,
      data: currenciesData,
      error: currenciesError,
    },
  ] = useLazyQuery(QUERY_GET_CURRENCIES, { fetchPolicy: "network-only" });
  const [
    deposit,
    { loading: loadingDeposit, data: depositData, error: depositError },
  ] = useMutation(DEPOSIT);
  const [
    depositCrypto,
    {
      loading: loadingDepositCrypto,
      data: depositCryptoData,
      error: depositCryptoError,
    },
  ] = useMutation(DEPOSIT_CRYPTO);
  const [
    getSumsubWebSdkLink,
    { data: sumsubVerifyUrl, loading: sumsubLoading, error: sumsubError },
  ] = useLazyQuery(QUERY_GET_SUMSUB_WEB_SDK_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data && data.sumsubWebSdkLink) {
        setIframeUrl(data.sumsubWebSdkLink);
      }
    },
  });
  const [showCurrencySelector, setShowCurrencySelector] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<string>("");
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [depositAmount, setDepositAmount] = useState<string>("1");
  const [ccFee, setCCFee] = useState<number>(0);
  const [totalBalance, setTotalBalance] = useState<string>("0");
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<Currency | null>(
    null
  );
  const [depositMethod, setDepositMethod] = useState<string>();
  const [infoMessage, setInfoMessage] = useState<string | null>();
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [goTOVerification, setGoTOVerification] = useState<boolean>(false);

  useAuthAndErrorHandling(error);
  useAuthAndErrorHandling(paymentMethodError);
  useAuthAndErrorHandling(transactionError);
  useAuthAndErrorHandling(getBalanceError);
  useAuthAndErrorHandling(currenciesError);
  useAuthAndErrorHandling(sumsubError);

  useEffect(() => {
    setTotalBalance(depositAmount);
    if (!isNaN(parseInt(depositAmount))) {
      setCCFee(parseInt(depositAmount) * 100 * 0.025); // 2.5% fee{
    } else {
      setCCFee(0);
    }
  }, [depositAmount]);

  useEffect(() => {
    listPaymentMethods();
    getTransactions();
    getBalance();
    getcurrencies();
  }, []);

  // useEffect(() => {
  //   loguser();
  // }, [user]);

  useEffect(() => {
    setErrorMessage(depositError?.message);
  }, [depositError]);

  useEffect(() => {
    if (selectedCurrency !== null) {
      setDepositAmount("0");
    } else {
      setDepositAmount("1");
    }
  }, [selectedCurrency]);

  useEffect(() => {
    if (depositData) {
      if (!infoMessage) {
        setShowDepositModal(false);
      }
      getTransactions();
      getBalance();
    }
    if (depositCryptoData) {
      getTransactions();
      getBalance();
    }
  }, [depositData, depositCryptoData]);

  function handlePaymentMethodClick(payment_method_id: string) {
    if (showCurrencySelector === false) {
      setSelectedCurrency(null);
      setSelectedPaymentMethod(payment_method_id);
    } else {
      setShowCurrencySelector(false);
      setSelectedCurrency(null);
      setSelectedPaymentMethod(payment_method_id);
    }
  }

  function handleSumsubVerification() {
    getSumsubWebSdkLink();
  }

  function renderPaymentMethod(paymentMethod: any) {
    switch (paymentMethod.type) {
      case "card":
        return (
          <div
            key={paymentMethod.id}
            className="card"
            style={{ flex: "1 1 40%" }}
          >
            <div className="flex justify-between">
              <p>Type</p>
              <p className="font-semibold">Card</p>
            </div>
            <div className="flex justify-between">
              <p>Brand</p>
              <p className="font-semibold">
                {paymentMethod.card.display_brand?.toUpperCase()}
              </p>
            </div>
            <div className="flex justify-between">
              <p>Expiration</p>
              <p className="font-semibold">
                {paymentMethod.card.exp_month} / {paymentMethod.card.exp_year}
              </p>
            </div>
            <div className="flex justify-between">
              <p>Last 4 digits</p>
              <p className="font-semibold">{paymentMethod.card.last4}</p>
            </div>
          </div>
        );
      case "sepa_debit":
        return (
          <div
            key={paymentMethod.id}
            className="card"
            style={{ flex: "1 1 40%" }}
          >
            <div className="flex justify-between">
              <p>Type</p>
              <p className="font-semibold">SEPA Debit</p>
            </div>
            <div className="flex justify-between">
              <p>Bank code</p>
              <p className="font-semibold">
                {paymentMethod.sepa_debit.bank_code}
              </p>
            </div>
            <div className="flex justify-between">
              <p>Country</p>
              <p className="font-semibold">
                {paymentMethod.sepa_debit.country}
              </p>
            </div>
            <div className="flex justify-between">
              <p>Last 4 digits</p>
              <p className="font-semibold">{paymentMethod.sepa_debit.last4}</p>
            </div>
          </div>
        );
    }
  }

  function renderPaymentMethodForDeposit(paymentMethod: any) {
    switch (paymentMethod.type) {
      case "card":
        return (
          <div
            key={paymentMethod.id}
            className={`card flex justify-around selectable-payment-method ${
              paymentMethod.id === selectedPaymentMethod &&
              showCurrencySelector === false
                ? " selected"
                : ""
            }`}
            onClick={() => handlePaymentMethodClick(paymentMethod.id)}
          >
            <p className="font-semibold">
              {paymentMethod.card.display_brand.toUpperCase(0)}
            </p>
            <p className="font-semibold">Card</p>
            <p className="font-semibold">
              {paymentMethod.card.exp_month} / {paymentMethod.card.exp_year}
            </p>
            <p className="font-semibold">{paymentMethod.card.last4}</p>
          </div>
        );
      case "sepa_debit":
        return (
          <div
            key={paymentMethod.id}
            className={`card flex justify-around selectable-payment-method ${
              paymentMethod.id === selectedPaymentMethod &&
              showCurrencySelector === false
                ? " selected"
                : ""
            }`}
            onClick={() => handlePaymentMethodClick(paymentMethod.id)}
          >
            <p className="font-semibold">SEPA Debit</p>
            <p className="font-semibold">{paymentMethod.sepa_debit.country}</p>
            <p className="font-semibold">{paymentMethod.sepa_debit.last4}</p>
          </div>
        );
    }
  }
  function handleCurrencyChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const selectedCurrencyId = event.target.value;
    if (selectedCurrencyId === "") {
      setSelectedCurrency(null);
    } else {
      const selectedCurrency =
        currenciesData?.currencies.find(
          (currency: Currency) => currency.id === selectedCurrencyId
        ) || null;
      setSelectedCurrency(selectedCurrency);
      setSelectedPaymentMethod("crypto");
    }
  }

  function doDeposit(e: FormEvent<HTMLButtonElement>) {
    e.preventDefault();

    if (
      user &&
      user.me.status === UserStatus.Unverified &&
      parseInt(depositAmount) + balanceData.getBalance > 1
    ) {
      setErrorMessage(
        "Please verify your account to deposit more than 1 token"
      );
      return;
    }

    if (!selectedPaymentMethod) {
      return;
    }

    const depositAmountInt = parseInt(depositAmount);
    if (depositAmountInt <= 0) {
      setErrorMessage("Please enter at least 1 token");
      setDepositAmount("1");
      return;
    }

    if (selectedPaymentMethod === "crypto") {
      depositCrypto({
        variables: {
          amount: depositAmountInt,
          currency: selectedCurrency?.name,
        },
      });
    } else {
      deposit({
        variables: {
          paymentMethodId: selectedPaymentMethod,
          amount: depositAmountInt * 100 * 100, // convert to cents
          currency: "gbp",
        },
      });
    }

    if (depositAmountInt > 10) {
      setInfoMessage("Our sales team has been notified and will contact you soon regarding your deposit. Deposits over £1000 require additional follow-up to ensure a smooth and efficient process. Thank you for your patience.");
      return;
    }
  }

  useEffect(() => {
    if (depositData && depositData.depositFiat?.id) {
      const { status, next_action } = depositData.depositFiat;

      if (status === "requires_action" && next_action && next_action.url) {
        // If next_action contains a URL, navigate to the 3D Secure redirect
        window.open(next_action.url, "_blank");
      }
    }
  }, [depositData, navigate]);

  if (user && user.me.status === UserStatus.Unverified && goTOVerification) {
    return (
      <>
        <GlobalErrorHandler />
        <Sidebar />
        {iframeUrl && (
          <div className=" p-4 content">
            <iframe
              src={iframeUrl}
              title="Sumsub Verification"
              width="100%"
              height={800}
              allow="camera; microphone"
            ></iframe>
          </div>
        )}
        {!iframeUrl && (
          <section className="content vault">
            <h1 className="font-sans text-deep-blue tracking-normal text-3xl mb-8 font-bold leading-normal">
              Vault
            </h1>
            <div className="flex flex-wrap flex-col align-middle items-center bg-white justify-between p-12 rounded-lg mb-8 mt-8 border-gray-200">
              <img
                src={VerificationImg}
                alt="Verification"
                className="w-20 h-20"
              />
              <p className="text-2xl font-bold p-4">Verify to invest</p>
              <p className=" text-lg p-4 max-w-md text-center mb-2">
                Complete your KYC verification to unlock the ability to deposite
                funds and start investing. This ensures the security and
                integrity of our platform.{" "}
              </p>
              <div
                className="btn p-8"
                onClick={() => handleSumsubVerification()}
              >
                Start verification
              </div>
            </div>
          </section>
        )}
      </>
    );
  }
  if (user && user.me.status === UserStatus.VerificationInProgress) {
    return (
      <>
        <GlobalErrorHandler />
        <Sidebar />
        <section className="content vault">
          <h1 className="font-sans text-deep-blue tracking-normal text-3xl mb-8 font-bold leading-normal">
            Vault
          </h1>
          <div className="flex flex-wrap flex-col align-middle items-center bg-white justify-between p-12 rounded-lg mb-8 mt-8 border-gray-200">
            <img
              src={VerificationImg}
              alt="Verification"
              className="w-20 h-20"
            />
            <p className="text-2xl font-bold p-4">Verification in Progress</p>
            <p className=" text-lg p-4 max-w-md text-center mb-2">
              Your application is being reviewed to comply with our KYC
              requirements. You will be able to deposit money and start
              investing with PropNerd once your verification is complete.
            </p>
            <p className=" text-lg p-4 max-w-md text-center mb-2">
              An email will be sent to you upon completion of the verification
              process.
            </p>
          </div>
        </section>
      </>
    );
  }
  if (user && user.me.status === UserStatus.Blocked) {
    return (
      <>
        <GlobalErrorHandler />
        <Sidebar />
        <section className="content vault">
          <h1 className="font-sans text-deep-blue tracking-normal text-3xl mb-8 font-bold leading-normal">
            Vault
          </h1>
          <div className="flex flex-wrap flex-col align-middle items-center bg-white justify-between p-12 rounded-lg mb-8 mt-8 border-gray-200">
            <img
              src={VerificationImg}
              alt="Verification"
              className="w-20 h-20 tint-[#ff0000]"
            />
            <p className="text-2xl font-bold p-4 text-red">Account Blocked</p>
            <p className=" text-lg p-4 max-w-md text-center mb-2">
              Unfortunately, your KYC verification did not meet our requirements.
              You currently will not be able to deposit money or invest with
              PropNerd.
            </p>
            <p className=" text-lg p-4 max-w-md text-center mb-2">
              Please contact support at info@propnerd.io for further assistance.
            </p>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <GlobalErrorHandler />

      <Sidebar />

      {showPaymentModal && (
        <PaymentMethod
          onClose={() => {
            setShowPaymentModal(false);
            listPaymentMethods();
          }}
        />
      )}

      {showDepositModal && (
        <Modal
          title="Deposit"
          onClose={() => {
            setShowDepositModal(false);
            setInfoMessage(null);
          }}
        >
          {infoMessage && (
            <div className="mb-12  max-w-md px-12  py-6  border rounded-lg bg-light-grey">
              {infoMessage}
            </div>
          )}
          {errorMessage && (
            <div className="mb-12  max-w-md px-12  py-6  border rounded-lg bg-light-grey text-red-500 -600">
              {errorMessage}
            </div>
          )}

          {!infoMessage && (
            <form>
              {!depositCryptoData && (
                <>
                  <div className="flex items-center text-center">
                    <div className="text-center rounded-l-lg bg-light-grey p-4">
                      {selectedCurrency === null ||
                      showCurrencySelector === false
                        ? "Tokens"
                        : selectedCurrency?.name}
                    </div>
                    <input
                      type="number"
                      value={depositAmount}
                      min={"1"}
                      onChange={(e) => setDepositAmount(e.target.value)}
                      className="w-full input-control px-6 py-3 border rounded-r-lg font-semibold text-lg"
                    />
                  </div>
                  <div className="payment-breakdown bg-gray-50 p-4 rounded-lg mb-2 mt-2">
                    <h2 className="font-bold text-sm text-deep-blue mb-2">
                      Payment Breakdown
                    </h2>
                    <p className="text-xs mb-1">
                      Base Amount:{" "}
                      <span className="font-bold text-xs">
                        £
                        {!isNaN(parseInt(depositAmount) * 100)
                          ? parseInt(depositAmount) * 100
                          : 0}
                      </span>
                    </p>
                    <p className="text-xs mb-1">
                      Credit Card Fee:{" "}
                      <span className="font-bold text-xs">£{ccFee}</span>
                    </p>
                    <hr className="my-2" />
                    <p className="text-xs">
                      Total Amount:
                      <span className="font-bold text-xs">
                        {"£" +
                          (!isNaN(parseInt(depositAmount) * 100)
                            ? parseInt(depositAmount) * 100 + ccFee
                            : 0)}
                      </span>
                    </p>
                    <p className="text-xs text-gray-600 mt-2">
                      This total includes the deposit amount and the credit card
                      fee.
                    </p>
                  </div>
                  <br />
                  {showCurrencySelector && (
                    <select
                      className="w-full border rounded-md p-4 mb-4 font-light "
                      value={selectedCurrency?.id || ""}
                      onChange={handleCurrencyChange}
                    >
                      <option value={""}>Select Currency</option>
                      {currenciesData?.currencies.map((currency: Currency) => (
                        <option key={currency.id} value={currency.id}>
                          {currency.name}
                        </option>
                      ))}
                    </select>
                  )}
                  <div className="flex justify-between mb-6 gap-4">
                    <div className="border rounded-md p-4 w-1/2">
                      <p className="font-light">Current Tokens</p>
                      <p className="font-semibold text-lg">
                        {balanceData?.getBalance / 100 / 100}
                      </p>
                    </div>
                    {!showCurrencySelector && (
                      <div className="border rounded-md p-4 w-1/2">
                        <p className="font-light">New Tokens</p>
                        <p className="font-semibold text-lg">
                          {parseInt(totalBalance) || ""}
                        </p>
                      </div>
                    )}
                  </div>

                  {paymentMethodData &&
                    paymentMethodData.listPaymentMethods.length === 0 && (
                      <p
                        className="font-semibold text-red-500"
                        style={{ maxWidth: 300 }}
                      >
                        You need to add a card or bank account first before you
                        can deposit an amount into your vault
                      </p>
                    )}
                  {paymentMethodData &&
                    paymentMethodData.listPaymentMethods.length > 0 && (
                      <>
                        <p className="font-semibold mb-2 text-center">
                          Choose a payment method
                        </p>
                        <div
                          className="flex flex-col gap-4 mb-6"
                          style={{ maxHeight: "300px", overflowY: "auto" }}
                        >
                          {paymentMethodData &&
                            paymentMethodData.listPaymentMethods.length > 0 &&
                            paymentMethodData.listPaymentMethods.map(
                              renderPaymentMethodForDeposit
                            )}
                        </div>
                        {/* <div className= {`card flex justify-around selectable-payment-method mb-6 ${showCurrencySelector === true ? ' selected' : ''}`} onClick={() =>{
                if(selectedPaymentMethod !== '' && showCurrencySelector === false){
                  setSelectedPaymentMethod('');
                }
                if(showCurrencySelector){
                  setSelectedCurrency(null)
                }
                setShowCurrencySelector(!showCurrencySelector)
              }}>
                <p className="font-semibold">Pay via Cryptocurrency</p>
              </div> */}

                        {/* important note */}
                        <div className="rounded-lg border-1.5 border-[#F8D5AE] max-w-md bg-[#FDF5EC]  p-4 flex flex-col gap-8 mb-6">
                          <div className="flex gap-2">
                            <img
                              src={ImportantNoticeImg}
                              alt="Shield warning"
                            />{" "}
                            <p className="font-bold text-[#14223D] ">
                              Important Note
                            </p>{" "}
                          </div>
                          {user &&
                            user.me.status === UserStatus.Active &&
                            (parseInt(depositAmount) <= 10 ||
                            depositAmount === "" ? (
                              <p className="text-[#768195]">
                                Once deposited,{" "}
                                <span className="text-[#14223D] font-bold ">
                                  tokens are non-refundable
                                </span>{" "}
                                due to the immutable nature of blockchain
                                transactions. All deposits and investments are{" "}
                                <span className="font-bold text-[#14223D]">
                                  {" "}
                                  secured on Solana's blockchain
                                </span>
                                , ensuring{" "}
                                <span className="font-bold text-[#14223D]">
                                  transparency
                                </span>{" "}
                                and{" "}
                                <span className="font-bold text-[#14223D]">
                                  security
                                </span>
                                .
                              </p>
                            ) : (
                              <p className="text-[#768195]">
                                <span className="font-bold text-[#14223D]">
                                  Deposits over £1000
                                </span>{" "}
                                require additional follow-up to ensure a{" "}
                                <span className="font-bold text-[#14223D]">
                                  smooth and efficient process. 
                                </span>
                                {" "} Our sales team will be notified on deposits over £1,000 and they will get {" "}
                                <span className="font-bold text-[#14223D]">
                                in touch with you
                                </span>{" "}
                              </p>
                            ))}

                          {user &&
                            user.me.status === UserStatus.Unverified &&
                            (parseInt(depositAmount) + balanceData?.getBalance >
                            1 ? (
                              <div className="flex-col text-center">
                                <p className="text-[#768195] mb-2">
                                  To deposit more than{" "}
                                  <span className="text-[#14223D] font-bold ">
                                    £100
                                  </span>
                                  , we require you to complete our{" "}
                                  <span className="text-[#14223D] font-bold ">
                                    KYC verification
                                  </span>
                                  . This quick process ensures your account's
                                  security and complies with regulations,
                                  allowing you to seamlessly invest with peace
                                  of mind.
                                </p>
                                <div
                                  className="btn p-6"
                                  onClick={() => setGoTOVerification(true)}
                                >
                                  Verify Now
                                </div>
                              </div>
                            ) : (
                              <p className="text-[#768195]">
                                Once deposited,{" "}
                                <span className="text-[#14223D] font-bold ">
                                  tokens are non-refundable
                                </span>{" "}
                                due to the immutable nature of blockchain
                                transactions. All deposits and investments are{" "}
                                <span className="font-bold text-[#14223D]">
                                  {" "}
                                  secured on Solana's blockchain
                                </span>
                                , ensuring{" "}
                                <span className="font-bold text-[#14223D]">
                                  transparency
                                </span>{" "}
                                and{" "}
                                <span className="font-bold text-[#14223D]">
                                  security
                                </span>
                                .
                              </p>
                            ))}
                        </div>
                        <button
                          className="btn py-6 w-full"
                          title={
                            !selectedPaymentMethod
                              ? "Select a payment method"
                              : ""
                          }
                          onClick={doDeposit}
                          disabled={
                            loadingDeposit ||
                            loadingDepositCrypto ||
                            !selectedPaymentMethod ||
                            (user &&
                              user.me.status === UserStatus.Unverified &&
                              parseInt(depositAmount) +
                                balanceData?.getBalance >
                                1)
                          }
                        >
                          {loadingDeposit || loadingDepositCrypto
                            ? "Depositing..."
                            : "Deposit"}
                        </button>
                      </>
                    )}
                </>
              )}
              {!loadingDepositCrypto && depositCryptoData && (
                <p className="text-center border max-w-md  m-4 rounded-md p-4 ">
                  Please deposit {depositAmount} {selectedCurrency?.name} into
                  this wallet account:
                  <br />
                  <p className="font-bold text-wrap px-5 py-2.5 bg-gray-200 rounded-md m-2 break-words">
                    {depositCryptoData.depositCrypto.cryptoAddress}
                  </p>
                  {depositCryptoData.depositCrypto.tag && (
                    <>
                      <p> with tag:</p>
                      <p className=" font-bold break-words px-5 py-2.5  m-2 rounded-md  bg-gray-200">
                        {depositCryptoData.depositCrypto.tag}
                      </p>
                    </>
                  )}
                  <br />
                  Once deposited, the amount will be converted to PropNerd
                  tokens and be ready for investing.
                </p>
              )}
            </form>
          )}
        </Modal>
      )}

      <section className="content vault">
        <h1 className="font-sans text-deep-blue tracking-normal text-3xl mb-8 font-bold leading-normal">
          Vault
        </h1>
        <div className="flex flex-row m-flex-col justify-between gap-6 flex-wrap">
          {loadingBalance ? (
            <VaultSkeleton width={responsiveSize.width} height={200} />
          ) : (
            <div className="card flex flex-1  flex-col md:flex-row gap-4">
              <div className="flex flex-3 flex-col justify-around">
                <p className="text-gray-800 text-lg">Cash Balance</p>
                <h3 className="text-4xl font-sans text-deep-blue tracking-normal font-bold leading-normal">
                  {toCurrencyDisplay(balanceData?.getBalance)}
                </h3>
              </div>
              <div className="flex flex-1 flex-col gap-4">
                <a
                  href="#deposit"
                  className="btn btn-primary"
                  onClick={() => setShowDepositModal(true)}
                >
                  Deposit
                </a>
                <a href="#withdraw" className="btn btn-secondary">
                  Withdraw
                </a>
              </div>
            </div>
          )}
          {loadingBalance ? (
            <VaultSkeleton width={responsiveSize.width} height={200} />
          ) : (
            <div className="card flex flex-1 lg:flex-row flex-col  gap-4">
              <div className="flex flex-3 flex-col justify-around">
                <p className="text-gray-800 text-lg">Tokens</p>
                <h3 className="text-4xl font-sans text-deep-blue tracking-normal font-bold leading-normal">
                  {(balanceData?.getBalance || 0) / 100 / 100}
                </h3>
              </div>
              {user && (user.me.investorProfile as any).solanaWalletAddress && (
                <div className="flex flex-1 flex-col gap-4">
                  <a
                    href={`https://token.propnerd.io/address/${
                      (user.me?.investorProfile as any).solanaWalletAddress
                    }`}
                    className="btn btn-secondary"
                    target="_blank"
                  >
                    View Wallet
                  </a>
                  <p className="ml-0 md:-ml-8 lg:-ml-14 mt-14 flex items-center">
                    <i className="m-1 lni lni-wallet"></i>
                    <span className="break-all">{`${
                      (user.me.investorProfile as any).solanaWalletAddress
                    }`}</span>
                  </p>
                </div>
              )}
            </div>
          )}
        </div>

        <h2 className="font-sans text-deep-blue tracking-normal text-2xl mb-4 mt-8 font-bold leading-normal">
          Transactions
        </h2>
        <div className="transactions card w-full">
          <table className="w-full">
            <thead>
              <tr>
                <th className="text-left font-semibold border-b">Type</th>
                <th className="text-left font-semibold border-b">Status</th>
                <th className="text-left font-semibold border-b">Date</th>
                <th className="text-left font-semibold border-b">Amount</th>
              </tr>
            </thead>
            <tbody>
              {loadingTransactions ? (
                <TransactionsSkeleton
                  width={responsiveSize.width}
                  height={100}
                />
              ) : (
                transactionData &&
                transactionData.getTransactions.length === 0 && (
                  <tr>
                    <td className="empty text-center" colSpan={4}>
                      No transactions
                    </td>
                  </tr>
                )
              )}
              {transactionData &&
                transactionData.getTransactions.length > 0 &&
                transactionData.getTransactions.map((transaction: any) => (
                  <tr key={transaction.code}>
                    <td className="text-left">{transaction.type}</td>
                    <td className="text-left">{transaction.status}</td>
                    <td className="text-left">
                      {format(transaction.createdAt, "PPP")}
                    </td>
                    <td className="text-left">
                      {toCurrencyDisplay(transaction.amount)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <h2 className="font-sans text-deep-blue tracking-normal text-2xl mb-4 mt-8 font-bold leading-normal">
          Payment Methods
        </h2>
        <div className="deposit-methods flex flex-row m-flex-col gap-6 flex-wrap">
          {loadingPaymentMethods ? (
            <VaultSkeleton width={responsiveSize.width} height={200} />
          ) : (
            paymentMethodData &&
            paymentMethodData.listPaymentMethods.length > 0 && (
              <div
                className="deposit-method flex gap-4"
                style={{ flexWrap: "wrap", flex: "1 1 50%" }}
              >
                {paymentMethodData &&
                  paymentMethodData.listPaymentMethods.length > 0 &&
                  paymentMethodData.listPaymentMethods.map(renderPaymentMethod)}
              </div>
            )
          )}
          <div className="deposit-method">
            {loadingPaymentMethods ? (
              <VaultSkeleton width={responsiveSize.width} height={200} />
            ) : (
              <div className="deposit-method-content card">
                <p>
                  Add a card or bank to enjoy instant deposits from anywhere in
                  the world
                </p>
                <a
                  href="#add-card"
                  className="btn btn-secondary btn-full"
                  onClick={() => setShowPaymentModal(true)}
                >
                  Add new card
                </a>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
