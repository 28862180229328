import React, { useState } from "react";
import ArrowDownIcon from "../../assets/svgs/direction-down.svg"; // Replace with actual path to your SVG
import ArrowUpIcon from "../../assets/svgs/arrow-up.svg"; // Replace with actual path to your SVG
import Financials from "./financials";
import Documents from "./documents";
import { htmlParagraphs } from "../../utils/string.util";
import { toCurrencyDisplay } from "../../utils/currency.util";

interface FinancialDataProps {
  propertyPrice: string;
  transactionCosts: string;
  platformFee: string;
  investmentCost: string;
  annualGrossRent: string;
  serviceCharges: string;
  maintainCharges: string;
  annualNetIncome: string;
}
interface Document {
  id: string;
  filename: string;
  url: string;
}

interface TabComponentProps {
  propertyDescription: string;
  financialData: FinancialDataProps;
  documents: Document[] | null;
  propertyId: string;
  initialCartAmount: number;
  onAddToCart: (amount: number) => void;
  cartErrorMessage: string | undefined;
  onAmountChange: (amountStr: string) => void;
  pledgeLoading: boolean;
}

const TabComponent: React.FC<TabComponentProps> = ({
  propertyDescription,
  financialData,
  documents,
  propertyId,
  initialCartAmount,
  onAddToCart,
  cartErrorMessage,
  onAmountChange,
  pledgeLoading,
}) => {
  const [activeTab, setActiveTab] = useState("Details");
  const [isExpanded, setIsExpanded] = useState(false);
  const [cartAmount, setCartAmount] = useState(initialCartAmount);

  const handleAddToCart = () => {
    onAddToCart(cartAmount);
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = e.currentTarget.value;
    setCartAmount(parseInt(amount));
    onAmountChange(amount);
  };

  const incrementAmount = () => {
    const newAmount = cartAmount + 1;
    setCartAmount(newAmount);
    onAmountChange(newAmount.toString());
  };

  const decrementAmount = () => {
    if (cartAmount > 1) {
      const newAmount = cartAmount - 1;
      setCartAmount(newAmount);
      onAmountChange(newAmount.toString());
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case "Details":
        return (
          <div className="mt-6 rounded-xl bg-[#f0f4ff] p-5 sm:p-10">
            <h3 className="text-black text-2xl font-bold leading-normal tracking-[-0.48px]">
              Property Description
            </h3>
            <div
              className={`tab-details-text ${
                isExpanded ? "expanded" : "collapsed"
              }`}
              dangerouslySetInnerHTML={{
                __html: htmlParagraphs(propertyDescription),
              }}
            />
            <div
              className="flex items-center justify-center cursor-pointer font-bold text-gray-800 mt-2"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <img
                src={isExpanded ? ArrowDownIcon : ArrowDownIcon}
                alt={isExpanded ? "Read Less" : "Read More"}
                className={`mr-2 transition-transform duration-300 ease ${
                  isExpanded ? "rotate-180" : ""
                }`}
              />
              <span className="text-base">
                {isExpanded ? "Read Less" : "Read More"}
              </span>
            </div>
          </div>
        );
      case "Financials":
        return <Financials financialData={financialData} />;
      case "Timelines":
        return <div className="tab-content">Timelines Content</div>;
      case "Documents":
        return <Documents documents={documents} propertyId={propertyId} />;
      default:
        return null;
    }
  };

  return (
    <div className="w-full mx-auto">
      <div className="flex flex-wrap-reverse lg:flex-row justify-end lg:justify-between items-end lg:items-center mt-5 gap-1 lg:gap-20">
        {/* Tabs Section */}
        <div className="flex flex-col lg:flex-row bg-[#f3f1f1] p-3 sm:px-8 lg:gap-5 rounded-xl w-full lg:w-[50%] overflow-x-scroll">
          <button
            className={`flex-1 py-2.5 px-6 text-black text-base font-medium cursor-pointer rounded-xl transition-colors ${
              activeTab === "Details"
                ? "text-black font-extrabold bg-[#ffd000] rounded-lg"
                : "hover:text-black hover:bg-[#cdcdd1]"
            }`}
            onClick={() => setActiveTab("Details")}
          >
            Details
          </button>
          <button
            className={`flex-1 py-2.5 px-6 text-black text-base font-medium cursor-pointer rounded-xl transition-colors ${
              activeTab === "Financials"
                ? "text-black font-bold bg-[#ffd000] rounded-lg"
                : "hover:text-black hover:bg-[#cdcdd1]"
            }`}
            onClick={() => setActiveTab("Financials")}
          >
            Financials
          </button>
          <button
            className={`flex-1 py-2.5 px-6 text-black text-base font-medium cursor-pointer rounded-xl transition-colors ${
              activeTab === "Documents"
                ? "text-black font-bold bg-[#ffd000] rounded-lg"
                : "hover:text-black hover:bg-[#cdcdd1]"
            }`}
            onClick={() => setActiveTab("Documents")}
          >
            Documents
          </button>
        </div>

        
        
      </div>
      

      <div className="tab-content-container mt-5">{renderContent()}</div>
      
    </div>
  );
};

export default TabComponent;
