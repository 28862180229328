import { Link, useLocation, useNavigate } from "react-router-dom";
import { Sidebar } from "../../components/sidebar/sidebar";
import { AppRoute } from "../../routes";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import ImgContactPerson from '../../assets/img/properties/contact-person.jpeg';
import ImgMap from '../../assets/img/properties/property-map.png';
import { QUERY_GET_PROPERTY } from "../../graphql/queries/get-property";
import { QUERY_IS_PROPERTY_BOOKMARKED } from "../../graphql/queries/property-bookmark";
import { AuthService } from "../../services/auth.service";
import { addCurencySubunits, removeCurrencySubunits, toCurrencyDisplay, toHumanReadableCurrency, toTokens } from "../../utils/currency.util";
import { format, set } from "date-fns";
import { MUTATION_PLEDGE_TO_PROPERTY } from "../../graphql/mutations/pledge";
import { toPercentageDisplay } from "../../utils/string.util";
import { GlobalErrorHandler } from "../../components/error/global_error.component";
import { getPropertyImageUrl } from "../../utils/image.utils";
import { getPropertyDocumentUrl } from "../../utils/document.util";
import { Property } from "../../__generated__/graphql";
import ImgPdf from '../../assets/img/common/pdf.png';
import Zoom from 'react-medium-image-zoom'
import { Slider } from '@mui/material';
import 'react-medium-image-zoom/dist/styles.css'

// @ts-ignore
import DocSummary from '../../assets/docs/asset-summary.pdf'
// @ts-ignore
import DocMarketOverview from '../../assets/docs/market-overview.pdf'
// @ts-ignore
import DocSustainability from '../../assets/docs/sustainability.pdf'
// @ts-ignore
import DocWhitepaper from '../../assets/docs/whitepaper.pdf'
import PropertySkeleton from "../../components/skeleton/propertyskeleton";
import { MUTATION_ADD_PROPERTY_BOOKMARK, MUTATION_REMOVE_PROPERTY_BOOKMARK } from "../../graphql/mutations/property-bookmark";
import { useAuthAndErrorHandling } from "../../utils/invalid-token.util";
import { show as showIntercom, hide as hideIntercom } from "@intercom/messenger-js-sdk";
import { toHumanReadableCurrencyDisplay } from "../../utils/currency.util";

export function PropertyPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [investmentAmount, setInvestmentAmount] = useState(50000);



  const [getProperty, { data, loading, error }] = useLazyQuery<{ property: Property }>(QUERY_GET_PROPERTY, { errorPolicy: 'all' });
  const [pledgeToProperty, { data: pledgeData, loading: pledgeLoading, error: pledgeError }] = useMutation(MUTATION_PLEDGE_TO_PROPERTY, { errorPolicy: 'all' });
  const [checkBookmark, { data: bookMarkData, loading: bookmarkLoading, error: bookmarkError }] = useLazyQuery<{ isPropertyBookmarked: boolean }>(QUERY_IS_PROPERTY_BOOKMARKED, {
    onCompleted: (bookMarkData) => {
      setIsBookmarked(bookMarkData.isPropertyBookmarked);
    }
  });

  const [addBookmark,{ data: bookMarkAddData, loading: bookmarkAddLoading, error: bookmarkAddError }] = useMutation(MUTATION_ADD_PROPERTY_BOOKMARK);
  const [removeBookmark ,{ data: bookMarkRemoveData, loading: bookmarkRemoveLoading, error: bookmarkRemoveError }] = useMutation(MUTATION_REMOVE_PROPERTY_BOOKMARK);
  const [cartAmount, setCartAmount] = useState(5);
  const [cartErrorMessage, setCartErrorMessage] = useState<string>();
  const [propertyGrowth, setPropertyGrowth] = useState(calculatePropertyGrowth(50000, data?.property?.financials[0]?.annualAppreciation || 30));
  const [rentalYield, setRentalYield] = useState(calculateAnnualRentalYield(50000, data?.property?.rents[0]?.rent || 1000));

 
  useAuthAndErrorHandling(error);
  useAuthAndErrorHandling(bookmarkError);

  const handleSliderChange = (event: Event, value: number | number[], activeThumb: number) => {
    const newValue = Array.isArray(value) ? value[0] : value;
    setInvestmentAmount(newValue);
    setPropertyGrowth(calculatePropertyGrowth(newValue, data?.property?.financials[0]?.annualAppreciation || 30));
    setRentalYield(calculateAnnualRentalYield(newValue, data?.property?.rents[0]?.rent || 1000));
  };

  function calculatePropertyGrowth(initialInvestment: number, annualAppreciation: number): number {
    
    const appreciationRate = annualAppreciation / 100;
    const futureValue = initialInvestment * Math.pow( appreciationRate, 5);
    // const futureValuePercentage = (futureValue / initialInvestment) * 100;
    return parseFloat(futureValue.toFixed(0)); 
  }
  
  function calculateAnnualRentalYield(initialInvestment: number, rent: number): number {
    const investmentAmountFraction = investmentAmount/data?.property.price; 
    const yourRent = rent * investmentAmountFraction;
    const annualRent = yourRent * 12;
    //const annualRentalYield = (annualRent / initialInvestment)*100 ;
    return parseFloat(annualRent.toFixed(0)); 
  }


  useEffect(() => {
    if (!AuthService.authenticate(navigate)) {
      return;
    }
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    getProperty({ variables: { id } } );
    checkBookmark({ variables: { propertyId: id } });

    // showIntercom();
    return () => {
      // hideIntercom();
    }

  }, []);


  function addToCart() {
    if (data && data.property) {
      pledgeToProperty({ variables: { propertyId: data.property.id, amount: cartAmount*100*100 } });
    }
  }

  function onAmountChange(amountStr: string) {
    if (!data || !data.property) {
      return;
    }
    setCartErrorMessage(undefined);
    const amount = parseInt(amountStr);
    if (!amount) {
      setCartAmount(0);
      setCartErrorMessage('Minimum tokens you can invest: 5');
      return;
    }
    if (amount > data.property.price || amount > data.property.agreementPrice) {
      setCartErrorMessage('Amount cannot exceed property price');
    }
    if (amount < 5) {
      setCartErrorMessage('Minimum tokens you can invest: 5');
    }
    setCartAmount(amount);
  }
  
  async function handleBookmark() {
    const queryParams = new URLSearchParams(location.search);
    const propertyId = queryParams.get('id');

    if (isBookmarked) {
      await removeBookmark({ variables: { propertyId } });

    } else {
      await addBookmark({ variables: { propertyId } });

    }
    setIsBookmarked(!isBookmarked);
    window.location.reload();
  }

  useEffect(() => {
    if (pledgeData && pledgeData.pledgeAmountToProperty) {
      navigate(AppRoute.Cart);
    }
  }, [pledgeData])


  function calculateEstimatedROI(data: any) {
    const investmentCost =
      (data.price || data.agreementPrice) +
      data.transactionFee +
      data.platformFee;
  
    const annualNetIncome =
      (data.rents[0]?.rent * 12 || 0) -
      (data.financials[0]?.annualServiceCharges || 0) -
      (data.financials[0]?.annualManagementAndMaintenanceCharges || 0);
  
    if (investmentCost === 0) {
      return 0; // To prevent division by zero
    }
  
    const estimatedROI = (annualNetIncome / investmentCost) * 100;
    return estimatedROI.toFixed(2); // Return ROI rounded to 2 decimal places
  }

  return (
    <>
      <GlobalErrorHandler />
    
      <Sidebar />

      { loading ? (
        
          <PropertySkeleton/>

        ) : data && data.property && (
      <div className=" content property ">
        <div className="flex m-flex-column-reverse justify-between mb-6 ">
          <div className="breadcrumb">
            <Link className="text-sm " to={AppRoute.Properties}>Properties</Link>
            <span className="divider text-lg">&gt;</span>
            <span className="text-gray-500  text-sm ">{data.property.title}</span>
          </div>
          <div className="actions">
            {bookMarkData  && <a className={`btn ${isBookmarked ? '' : 'btn-secondary'}`} onClick={()=>handleBookmark()}>
              <span className="lni lni-bookmark" /> {isBookmarked ? 'Bookmarked' : 'Bookmark'} </a> }
          </div>
        </div>
        { data.property.images && !!data.property.images.length &&
          <div className="images mb-12 ">
            <div className="big-image"><Zoom><img className=" rounded-md " src={ getPropertyImageUrl(data.property.id, data.property.images[0]) } /></Zoom></div>
            <div className="small-images m-hidden">
            { data.property.images.map(image => 
              <Zoom>
              <img key={image.id} className=" rounded-md " src={getPropertyImageUrl(data.property.id, image)} />
              </Zoom>
            )}
            </div>
          </div>
        }
        <div className="flex flex-col max-w-fit md:flex-row gap-6 ">
          <div className="details card flex-3 max-w-fit">
            <h1 className="font-sans text-deep-blue tracking-normal text-3xl  font-bold leading-normal mb-2">{data.property.title}</h1>
            <div className="flex gap-4 mb-6 ">
              <span className="text-sm ">{data.property.bed} bed</span>
              <span className="text-sm ">{data.property.bath} bath</span>
              <span className="text-sm ">{data.property.sqFootage} sq.ft</span>
              <span className="text-sm ">{data.property.city.name}</span>
            </div>
            <hr className="border-0 h-0.5 bg-lighter-grey my-8" />

            <div className="flex flex-col gap-4 ">
              <div className="flex gap-2 ">
                <div className="icon" />
                <div className="flex flex-col gap-2 ">
                  <h3 className="font-semibold font-sans text-deep-blue tracking-normal  leading-normal ">{data.property.city.name}</h3>
                  <p className="font-light ">{data.property.city.excerpt}</p>
                </div>
              </div>
              { data.property.isRented &&
                <div className="flex gap-2 ">
                  <div className="icon" />
                  <div className="flex flex-col gap-2 ">
                    <h3 className="font-semibold  font-sans text-deep-blue tracking-normal  leading-normal">Rented</h3>
                    <p className="font-light ">Currently occupied and professionally managed by our team</p>
                  </div>
                </div>
              }
              { data.property.isRented && !!data.property.rents.length && 
                <div className="flex gap-2 ">
                  <div className="icon" />
                  <div className="flex flex-col gap-2 ">
                    <h3 className="font-semibold font-sans text-deep-blue tracking-normal leading-normal">Current rent is {toCurrencyDisplay(data.property.rents[0]?.rent)} per month</h3>
                    <p className="font-light ">Distributed monthly among all investors after standard charges and fees</p>
                  </div>
                </div>
              }
              <div className="flex gap-2 ">
                <div className="icon" />
                <div className="flex flex-col justify-between gap-4 flex-wrap ">
                    <div className="flex flex-col gap-2 ">
                      <h3 className="font-semibold font-sans text-deep-blue tracking-normal  leading-normal">{toPercentageDisplay(data.property.financials[0]?.projectedGrossYield)} annual gross yield</h3>
                      <p className="font-light ">With a net yield of {toPercentageDisplay(data.property.financials[0]?.projectedNetYield)}</p>
                    </div>
                    <div className="flex flex-col gap-4 ">
                      <h3 className="font-semibold font-sans text-deep-blue tracking-normal  leading-normal">{calculateEstimatedROI(data.property)}% Estimated ROI</h3>
                      <h3 className="font-semibold font-sans text-deep-blue tracking-normal  leading-normal">{data.property.pledges.length || 0} Investors</h3>
                    </div>
                </div>
              </div>
            </div>
            <hr className="border-0 h-0.5 bg-lighter-grey my-8" />
            <div>
              <h2 className=" font-sans text-deep-blue  tracking-normal text-2xl mb-4 mt-8 font-bold leading-normal ">Investment Calculator</h2>
              <h3 className="text-center text-lg text-gray-800  font-light  mb-2 mt-6 ">Projected investment return of</h3>
              <h3 className="text-center text-lg font-semibold  mb-6 ">{toHumanReadableCurrency(propertyGrowth / 100 / 100)} in 5 years</h3>
              <div className="flex m-flex-col justify-between border rounded-md  px-6  py-2 mb-6 ">
                <div className="flex flex-col gap-2 ">
                  <p className="font-light  text-gray-800  text-sm ">Investment</p>
                  <h4 className="font-sans text-deep-blue  tracking-normal font-bold leading-normal">{toHumanReadableCurrency(investmentAmount * 100)}</h4>
                </div>
                <div className="flex flex-col gap-2 ">
                  <p className="font-light  text-gray-800  text-sm ">Total rental income</p>
                  <h4 className="font-sans text-deep-blue  tracking-normal font-bold leading-normal">{toHumanReadableCurrency(rentalYield)}</h4>
                </div>
                <div className="flex flex-col gap-2 ">
                  <p className="font-light  text-gray-800  text-sm ">Value appreciation</p>
                  <h4 className="font-sans text-deep-blue  tracking-normal font-bold leading-normal">{toHumanReadableCurrency(calculatePropertyGrowth(investmentAmount, data.property.financials[0]?.annualAppreciation || 30) / 100 / 100)}</h4>
                </div>
              </div>
              <div className="chart">
              </div>
              <div className="flex flex-col gap-4 ">
                <div>
                  <div className="flex justify-between mb-6  ">
                    <p className="font-light ">Initial Investment</p>
                    <p className="font-semibold ">{(toHumanReadableCurrency(investmentAmount*100,0))}</p>
                  </div>
                  <Slider
                    value={investmentAmount}
                    onChange={handleSliderChange}
                    min={100}
                    max={800000}
                    valueLabelDisplay="auto"
                    aria-labelledby="investment-slider"
                    sx={{
                      '& .MuiSlider-thumb': {
                        color: '#e2af17',
                      },
                      '& .MuiSlider-track': {
                        color: '#e2af17',
                      },
                      '& .MuiSlider-rail': {
                        color: '#e2af17',
                      },
                    }}
                  />
                </div>
                <div>
                  <div className="flex justify-between mb-6  ">
                    <p className="font-light ">Property value growth (5 year)</p>
                    <p className="font-semibold ">{toHumanReadableCurrency(propertyGrowth*100,0)}</p>
                  </div>
                  <Slider
                    value={propertyGrowth}
                    min={0}
                    max={1200000 * 5}
                    valueLabelDisplay="auto"
                    aria-labelledby="investment-slider"
                    disabled
                    sx={{
                      '& .MuiSlider-thumb': {
                        color: '#e2af17',
                      },
                      '& .MuiSlider-track': {
                        color: '#e2af17',
                      },
                      '& .MuiSlider-rail': {
                        color: '#e2af17',
                      },
                    }}
                  />
                </div>
                <div>
                  <div className="flex justify-between mb-6  ">
                    <p className="font-light ">Expected annual rental yield</p>
                    <p className="font-semibold ">{rentalYield>1000 ?  toHumanReadableCurrency(rentalYield*100,0) : rentalYield.toString() }</p>
                  </div>
                  <Slider
                    value={rentalYield}
                    min={0}
                    max={5000}
                    valueLabelDisplay="auto"
                    aria-labelledby="investment-slider"
                    disabled
                    sx={{
                      '& .MuiSlider-thumb': {
                        color: '#e2af17',
                      },
                      '& .MuiSlider-track': {
                        color: '#e2af17',
                      },
                      '& .MuiSlider-rail': {
                        color: '#e2af17',
                      },
                    }}
                  />
                </div>
              </div>
              <div className="bg-light-grey rounded-md  text-center px-6   py-2">
                <p className="font-light  text-xs">Default values are based on property numbers</p>
              </div>
            </div>
            
            <h2 className=" font-sans text-deep-blue  tracking-normal text-2xl mb-4 mt-8 font-bold leading-normal ">Property Description</h2>
            <p className="font-light  mb-6  ">PropNerd is offering an opportunity to invest in an {data.property.bed}-bedroom apartment in {data.property.city.name}.</p>
            <p className="font-light  mb-6  ">{ data.property.city.propertyDescription }</p>

            <h2 className=" font-sans text-deep-blue  tracking-normal text-2xl mb-4 mt-8 font-bold leading-normal ">Financials</h2>
            <div className="flex flex-col sm:flex-row  gap-6 mt-6  ">
              <div className="flex flex-1 flex-col gap-4 ">
                <div className="flex justify-between">
                  <p className="font-semibold ">Property Cost</p>
                </div>
                <div className="flex justify-between">
                  <p className="font-light ">Property Price</p>
                  <p className="font-semibold ">{toCurrencyDisplay(data.property.price || data.property.agreementPrice)}</p>
                </div>
                <div className="flex justify-between">
                  <p className="font-light ">Transaction Costs</p>
                  <p className="font-semibold ">{toCurrencyDisplay(data.property.transactionFee)}</p>
                </div>
                <div className="flex justify-between border-b  pb-6  ">
                  <p className="font-light ">PropNerd Fee</p>
                  <p className="font-semibold ">{toCurrencyDisplay(data.property.platformFee)}</p>
                </div>
                <div className="flex justify-between">
                  <p className="font-light ">Investment Cost</p>
                  <p className="font-semibold  text-yellow-500 ">{toCurrencyDisplay((data.property.price || data.property.agreementPrice) + data.property.transactionFee + data.property.platformFee)}</p>
                </div>
              </div>
              <div className="flex flex-1 flex-col gap-4 ">
                <div className="flex justify-between">
                  <p className="font-semibold ">Rental income (Year 1)</p>
                </div>
                <div className="flex justify-between">
                  <p className="font-light ">Annual gross rent</p>
                  <p className="font-semibold ">{toCurrencyDisplay(data.property.rents[0]?.rent * 12)}</p>
                </div>
                <div className="flex justify-between">
                  <p className="font-light ">Service Charges</p>
                  <p className="font-semibold ">({toCurrencyDisplay(data.property.financials[0]?.annualServiceCharges)})</p>
                </div>
                <div className="flex justify-between border-b  pb-6  ">
                  <p className="font-light ">Mgmt / Maint.</p>
                  <p className="font-semibold ">({toCurrencyDisplay(data.property.financials[0]?.annualManagementAndMaintenanceCharges)})</p>
                </div>
                <div className="flex justify-between">
                  <p className="font-light ">Annual net income</p>
                  <p className="font-semibold  text-yellow-500 ">{toCurrencyDisplay(
                    (data.property.financials[0]?.annualRent || 0)
                    - data.property.financials[0]?.annualServiceCharges
                    - data.property.financials[0]?.annualManagementAndMaintenanceCharges
                  )}</p>
                </div>
                <div className="bg-light-grey rounded-md  text-center px-6   py-2">
                  <p className="font-light  text-xs">This is an estimate for the 1st year of ownership</p>
                </div>
              </div>
            </div>

            <h2 className=" font-sans text-deep-blue  tracking-normal text-2xl mb-4 mt-8 font-bold leading-normal ">Fundtime timeline</h2>
            <div className="flex">
              <div className="bg-light-grey rounded-md  px-6   py-2">
                <p className="font-light  text-xs">The timeline is an estimate. Actual dates may vary.</p>
              </div>
            </div>
            <div className="flex flex-col gap-4 mt-6  ">
              { data.property.timelineEvents.map((event: any, index: number) => (
              <div className="flex flex-col ">
                { index === 0 && (
                <div className="timeline-item current">
                  <div className="circle" />
                </div>
                )}
                <p className="font-light  text-sm ">{format(event.date, 'PPP')}</p>
                <p className="font-semibold ">{event.title}</p>
                <p className="text-sm ">{event.description}</p>
              </div>
              ))}
            </div>

            <h2 className=" font-sans text-deep-blue  tracking-normal text-2xl mb-4 mt-8 font-bold leading-normal ">Location</h2>
            <p className="font-semibold  flex items-center mb-6  "><span className="lni lni-map-marker text-yellow-500  mr-1 " /> {data.property.city.name}</p>
            <div className="mb-6  "><img className="rounded-lg" src={ ImgMap } style={{ width: '100%'}} /></div>
            <p className="font-light ">{data.property.city.description}</p>

            <h2 className=" font-sans text-deep-blue  tracking-normal text-2xl mb-4 mt-8 font-bold leading-normal ">Amenities</h2>
            <div className="flex flex-wrap gap-6 ">
              {data.property.amenities && data.property.amenities.map((amenity: any, index: number) => (
              <div className="flex gap-2 ">
                <span className="lni lni-gym" />
                <p>{amenity.title}</p>
              </div>
              ))}
            </div>

            <h2 className="mt-12 font-sans text-deep-blue  tracking-normal text-2xl mb-4  font-bold leading-normal ">Documents</h2>
            <div className="documents flex flex-col gap-4 ">
            <ul className="docs flex gap-2 flex-wrap">
            {data.property.documents && data.property.documents.map((document: any) => (
                  <li key={document.id} className="bg-slate-200 px-4 py-3 rounded">
                    <a href={getPropertyDocumentUrl(data.property.id, document)} rel="noreferrer" target="_blank" >
                      <img src={ImgPdf} alt="PDF" /> {document.filename}
                    </a>
                  </li>
                ))}
            </ul>
            {/* { data.property?.id === 'c7e67241-9eb6-4910-9430-8f6134fe8fd3' && (
              <>
                <ul className="docs flex gap-2">
                  <li className="bg-slate-200 px-4 py-3 rounded"><a href={ DocSummary } target="_blank"><img src={ImgPdf} alt="PDF" /> Summary</a></li>
                  <li className="bg-slate-200 px-4 py-3 rounded"><a href={ DocMarketOverview } target="_blank"><img src={ImgPdf} alt="PDF" /> Market Overview</a></li>
                  <li className="bg-slate-200 px-4 py-3 rounded"><a href={ DocSustainability } target="_blank"><img src={ImgPdf} alt="PDF" /> Sustainability</a></li>
                  <li className="bg-slate-200 px-4 py-3 rounded"><a href={ DocWhitepaper } target="_blank"><img src={ImgPdf} alt="PDF" /> Whitepaper</a></li>
                </ul>
              </>
            )} */}
              {/* <div className="flex justify-between items-center px-6  py-6   border rounded-md ">
                <div className="flex items-center gap-2 ">
                  <span className="lni lni-certificate" />
                  <p>Initial Valuation</p>
                </div>
                <span className="lni lni-download" />
              </div>
              <div className="flex justify-between items-center px-6  py-6   border rounded-md ">
                <div className="flex items-center gap-2 ">
                  <span className="lni lni-certificate" />
                  <p>Investor Memo</p>
                </div>
                <span className="lni lni-download" />
              </div>
              <div className="flex justify-between items-center px-6  py-6   border rounded-md ">
                <div className="flex items-center gap-2 ">
                  <span className="lni lni-certificate" />
                  <p>Projections</p>
                </div>
                <span className="lni lni-download" />
              </div> */}
            </div>

          </div>

          <div className="add-to-cart flex flex-1 flex-col gap-4 ">
            <div className=" max-w-full invest card">
              <p className="text-center mb-3">Property Price</p>
              <h3 className="text-4xl  text-center font-semibold  text-yellow-500  mb-3 ">{toCurrencyDisplay(data.property.price || data.property.agreementPrice)}</h3>
              <p className="text-xl  text-center font-semibold  text-yellow-500  mb-6 ">{toTokens(data.property.price || data.property.agreementPrice)} Tokens</p>
              <div className="progress">
                <div className="baseline" />
                <div className="current" style={{ width: '0%' }} />
              </div>
              <div className="flex justify-between">
                <p className="font-light  text-sm ">0% funded</p>
                <p className="font-light  text-sm ">{toCurrencyDisplay(data.property.price || data.property.agreementPrice)} available</p>
              </div>
              <div className="flex justify-between mt-6   mb-6 ">
                <p><span className="text-bg font-semibold  text-yellow-500 ">0</span> <span className="font-light ">investors</span></p>
                <p className="text-bg text-red-500 ">60 days left</p>
              </div>
              <div className="flex flex-col gap-4 bg-light-grey rounded-md  mt-6  p-4">
                <div className="flex justify-between">
                  <p className="text-gray-800 ">Annual rate of return</p>
                  <p className="font-semibold  text-right">{toPercentageDisplay(data.property.financials[0]?.annualizedReturn)}</p>
                </div>
                <div className="flex justify-between">
                  <p className="text-gray-800 ">Annual appreciation</p>
                  <p className="font-semibold  text-right">{toPercentageDisplay(data.property.financials[0]?.annualAppreciation)}</p>
                </div>
                <div className="flex justify-between">
                  <p className="text-gray-800 ">Projected gross yield</p>
                  <p className="font-semibold  text-right">{toPercentageDisplay(data.property.financials[0]?.projectedGrossYield)}</p>
                </div>
                <div className="flex justify-between">
                  <p className="text-gray-800 ">Projected net yield</p>
                  <p className="font-semibold  text-right">{toPercentageDisplay(data.property.financials[0]?.projectedNetYield)}</p>
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-2 justify-between mt-6 ">
                <div className=" flex items-center  text-center">
                  <input type="text" value={cartAmount || 0} onChange={e => onAmountChange(e.currentTarget.value)} className="input-control px-6  py-3  border rounded-r-lg  font-semibold  text-lg" />
                  <div className=" text-center rounded-l-lg  bg-light-grey p-4 ">Tokens</div>
                </div>
                <button className=" btn btn-primary " disabled={pledgeLoading || !!cartErrorMessage} onClick={addToCart}>Add to cart</button>
                
              </div>
              <p className="text-gray-500 text-sm">Amount being invested: {toCurrencyDisplay(cartAmount! * 100 * 100)?.toString() || 0} </p>
              <div className="flex gap-2 justify-between">
                {/* <a href="#invest-2000" className="btn btn-yellow btn-sm">+ £ 2,000</a>
                <a href="#invest-5000" className="btn btn-yellow btn-sm">+ £ 5,000</a>
                <a href="#invest-10000" className="btn btn-yellow btn-sm">+ £ 10,000</a> */}
              </div>
              { cartErrorMessage && (
              <div className="text-bg text-red-500  py-6 ">
                { cartErrorMessage }
              </div>
              )}
              <p className="text-sm  text-gray-800  mt-2">You won't be charged yet</p>
            </div>
            {/* <p className="text-center text-xs"><i className="lni lni-star" />0 people viewed this property</p> */}
          </div>

        </div>
      </div>
      )}
    </>
  )
}
