import { gql } from '@apollo/client';

export const QUERY_GET_PROPERTY = gql`query getProperty($id:String!) {
property(id:$id) {
    id
    title
    description
    code
    price
    transactionFee
    platformFee
    isRented
    bed
    bath
    sqFootage
    location
    city {
      name
      excerpt
      description
      propertyDescription
    }
    images {
      id
      ext
    }
    amenities {
      title
    }
    timelineEvents {
      title
      description
      date
    }
    financials {
      projectedAnnualRoIPercentage
      projectedAnnualRoIValue
      year3ProjectedAnnualRoIPercentage
      year3ProjectedAnnualRoIValue
      annualRent
      effectiveDate
      endDate
    }
    documents {
      id
      filename
      ext
    }
    pledges {
      id
      amount
      status
    }
    rents {
      date
      rent
      isPaid
      isDisbursed
    }
    createdAt
  }
}`
