import { gql } from "@apollo/client";

export const DEPOSIT = gql`
  mutation depositFiat(
    $paymentMethodId: String!
    $amount: Int!
    $currency: String!
  ) {
    depositFiat(
      paymentMethodId: $paymentMethodId
      amount: $amount
      currency: $currency
    ) {
      id
      status
      client_secret
      next_action {
        type
        url
      }
      
    }
  }
`;



export const DEPOSIT_CRYPTO = gql`
mutation depositCrypto($amount: BigInt!, $currency: String!) {
  depositCrypto(amount: $amount, currency: $currency) {
    cryptoAddress
    tag
  }
}
`;


export const FINALIZE_PAYMENT = gql`
mutation finalizePayment($paymentIntentId: String!) {
  finalizePayment(paymentIntentId: $paymentIntentId)
}
`;
