import { gql } from "@apollo/client";

export const MUTATION_PLEDGE_TO_PROPERTY = gql`mutation pledgeAmountToProperty($propertyId: String!, $amount: Int!) {
  pledgeAmountToProperty(propertyId: $propertyId, amount: $amount) {
    id
  }
}`

export const MUTATION_CONFIRM_PLEDGE = gql`mutation confirmPledge($pledgeId: String!, $amount: Int!) {
  confirmPledge(pledgeId: $pledgeId, amount: $amount) {
    id
  }
}`

export const MUTATION_DELETE_PLEDGE = gql`mutation deletePledge($pledgeId: String!) {
  deletePledge(pledgeId: $pledgeId) {
    id
  }
}`
