import { gql } from "@apollo/client";

export const QUERY_GET_ME = gql`query getMe {
  me {
    firstName
    lastName
    email
    phone
    investorProfile {
      id
      solanaWalletAddress
    }
    status
  }
}`