import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { FINALIZE_PAYMENT } from '../../graphql/mutations/deposit';
import { GlobalErrorHandler } from "../../components/error/global_error.component";
import { ReactComponent as SvgLogo } from '../../assets/img/common/logo.svg';

export function StripeRedirect() {
  const [paymentStatus, setPaymentStatus] = useState<'processing' | 'succeeded' | 'failed' | 'requires_action' | 'requires_payment_method' | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const hasFinalizedRef = useRef(false); // Using useRef to track if API call has been made
  const navigate = useNavigate();
  const location = useLocation();
  
  const paymentIntentId = new URLSearchParams(location.search).get('payment_intent');

  const [finalizePayment, { loading, data, error }] = useMutation(FINALIZE_PAYMENT , { errorPolicy: 'all' });

  // Ensure the mutation is fired only once using `hasFinalizedRef`
  useEffect(() => {
    const finalize = async () => {
      if (paymentIntentId && !hasFinalizedRef.current) {  // Check if it's already finalized
        console.log('calling API once');
        await finalizePayment({ variables: { paymentIntentId } });
        hasFinalizedRef.current = true; // Mark as finalized using ref
      }
    }

    finalize();
  }, [paymentIntentId, finalizePayment]);

  useEffect(() => {
    if (data) {
      const status = data.finalizePayment;
      setPaymentStatus(status);
      console.log('status', status);

      if (status === 'succeeded') {
        navigate('/vault'); // Redirect to vault or other success page
      } else if (status === 'requires_action') {
        setErrorMessage('Additional action is required to complete the payment.');
        //add delay to show the error message
        setTimeout(() => {
          navigate('/vault'); 
        }, 3000);
      } else if (status === 'failed') {
        setErrorMessage('The payment failed. Please try again.');
        setTimeout(() => {
          navigate('/vault'); 
        }, 3000);
      }else if (status === 'requires_payment_method') {
        setErrorMessage('The payment failed. Please try again.');
        setTimeout(() => {
          navigate('/vault'); 
        }, 3000);
      } 
    }

    if (error) {
      setErrorMessage(error.message || 'An error occurred while finalizing the payment.');
    }
  }, [data, error, navigate]);

  if (loading) {
    return (
      <section className='content items-center'>
        <div className=' min-w-full card text-xl text-center font-bold'>
          <GlobalErrorHandler />
          <SvgLogo className='max-w-52 mt-0 lg:mt-16 mx-auto  text-center mb-20' />
          <p className='text-xl text-center font-bold'>Processing your payment, please wait...</p>
          
        </div>
      </section>
    );
  }

  return (
    <div>
      <GlobalErrorHandler />
      <section className='content items-center'>
        <div className='min-w-full card flex flex-col justify-center align-middle items-center text-xl font-bold'>
        <SvgLogo className='max-w-52 mt-0 lg:mt-16 mx-auto  text-center mb-20' />
            {error && <p className='text-xl text-center font-bold pb-4'>{errorMessage}</p>}
            {paymentStatus === 'succeeded' && <p className='text-xl text-center font-bold'>Payment successful! Redirecting to your vault...</p>}
            {paymentStatus === 'requires_action' && <p className='text-xl text-center font-bold'>{errorMessage}</p>}
            {paymentStatus === 'failed' && <p className='text-xl text-center font-bold'>{errorMessage}</p>}
            {paymentStatus === 'requires_payment_method' && <p className='text-xl text-center font-bold'>{errorMessage}</p>}
            {error && <div className="btn p-4" onClick={()=> navigate('/vault')} >Go back</div>}
        </div>
      </section>
    </div>
  );
}
