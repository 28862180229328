import { useMutation, useQuery } from "@apollo/client";
import { GlobalErrorHandler } from "../../components/error/global_error.component";
import { Sidebar } from "../../components/sidebar/sidebar";
import { QUERY_GET_ME } from "../../graphql/queries/get-me";
import { User } from "../../__generated__/graphql";
import { useEffect, useState } from "react";
import ProfileSkeleton from "../../components/skeleton/profileskeleton";
import { useAuthAndErrorHandling } from "../../utils/invalid-token.util";
import { MUTATION_UPDATE_USER } from "../../graphql/mutations/user";
import 'react-phone-number-input/style.css';
import PhoneInputWithCountrySelect, { isValidPhoneNumber } from "react-phone-number-input";

export function ProfilePage() {
  const { data, loading, error } = useQuery<{ me: User }>(QUERY_GET_ME, { fetchPolicy: 'network-only' });

  const [updateUser, { data: updateData, loading: updateLoading }] = useMutation(MUTATION_UPDATE_USER, { fetchPolicy: 'network-only' });

  const [firstName, setFirstName] = useState<string | undefined>(undefined);
  const [lastName, setLastName] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [phone, setPhone] = useState<string | undefined>(undefined);

  useAuthAndErrorHandling(error);

  useEffect(() => {
    if (data?.me) {
      setFirstName(data.me.firstName ?? undefined);
      setLastName(data.me.lastName ?? undefined);
      setEmail(data.me.email ?? undefined);
      setPhone(data.me.phone ?? undefined);
    }
  }, [data]);

  function Save() {
    updateUser({
      variables: {
        firstName,
        lastName,
        phone
      }
    });
  }

  return (
    <>
      <GlobalErrorHandler />
      <Sidebar />
      {loading ? (
        <div className="content portfolio">
          <div className="card" style={{ maxWidth: 600 }}>
            <ProfileSkeleton />
          </div>
        </div>
      ) : (
        <section className="content portfolio">
          <h1 className="mb-4 font-sans text-deep-blue tracking-normal text-3xl font-bold leading-normal">Profile</h1>
          {data && data.me && (
            <form className="card" style={{ maxWidth: 600 }}>
              <div className="form-group">
                <label>First name</label>
                <input
                  autoFocus
                  value={firstName || ''}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="Enter your first name"
                />
              </div>
              <div className="form-group mt-6">
                <label>Last name</label>
                <input
                  value={lastName || ''}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Enter your last name"
                />
              </div>
              <div className="form-group mt-6">
                <label>Email address (cannot be changed)</label>
                <input
                  type="email"
                  value={email || ''}
                  disabled
                  placeholder="Enter your email address"
                />
              </div>
              <div className="form-group mt-6">
                <label>Phone number</label>
                <PhoneInputWithCountrySelect
                  defaultCountry="GB"
                  value={phone || ''}
                  onChange={setPhone}
                  placeholder="Enter your phone number"
                 
                />
              </div>
              <div className="form-group mt-6">
                <button type="submit" className="btn btn-lg" style={{ height: 50, maxWidth: 200 }} onClick={Save}>
                  Save
                </button>
              </div>
            </form>
          )}
        </section>
      )}
    </>
  );
}
