import { useMutation } from '@apollo/client';
import { FormEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as SvgLogo } from '../../../assets/img/common/logo.svg';
import { MUTATION_RESET_PASSWORD } from '../../../graphql/mutations/password-reset';
import { AppRoute } from '../../../routes';
import { GlobalErrorHandler } from '../../../components/error/global_error.component';

export function AccountResetPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [infoMessage, setInfoMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [resetId, setResetId] = useState<string>('');

  const [resetPassword, { loading, error, data }] = useMutation(MUTATION_RESET_PASSWORD, { errorPolicy: 'all' });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const idParam = queryParams.get('id');
    if (idParam) {
      setResetId(idParam);
    }
  }, [location.search]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
    }
  }, [error]);

  async function onSubmit(e: FormEvent) {
    e.preventDefault();
    if (newPassword.length < 8) {
      setErrorMessage('Password must be at least 8 characters long.');
      return;
    }

    setInfoMessage('Resetting password. Please wait...');
    try {
      const response = await resetPassword({ variables: { id: resetId, code: verificationCode, newPassword } });
      if (response.data.resetPassword.success) {
        setInfoMessage('Password reset successfully. Please log in with your new password.');
        navigate(AppRoute.AccountLogin);
      } else {
        setErrorMessage(response.data.resetPassword.message);
      }
    } catch (err: any) {
      setErrorMessage(err.message);
    }
  }

  return (
    <section className="content account flex flex-col">
      <GlobalErrorHandler />
      <SvgLogo className='max-w-52 -mt-16 mx-auto text-center' />
      <h1 className='font-sans text-deep-blue font-bold leading-normal text-5xl tracking-wide mt-24 mb-4'>Reset password</h1>
      <form className='card bg-white rounded-lg shadow-md mx-auto max-w-md p-8 w-1/2' onSubmit={onSubmit}>
        { infoMessage && <div className="mb-12 px-12 py-6 border rounded-lg bg-light-grey">{infoMessage}</div> }
        { errorMessage && <div className="mb-12 px-12 py-6 border rounded-lg bg-light-grey text-red-500 -600">{errorMessage}</div> }
        <div className="p-4 flex flex-col gap-2">
          <label className='text-base font-bold mb-2 tracking-normal -mt-2'>Verification code</label>
          <input className='box-border p-4 border border-gray-300 rounded w-full text-base' autoFocus type="text" value={verificationCode} placeholder='Enter your verification code' onChange={e => setVerificationCode(e.target.value)} />
        </div>
        <div className="p-4 flex flex-col gap-2">
          <label className='text-base font-bold mb-2 tracking-normal -mt-2'>New Password</label>
          <input className='box-border p-4 border border-gray-300 rounded w-full text-base' type="password" value={newPassword} placeholder='Enter your new password' onChange={e => setNewPassword(e.target.value)} />
        </div>
        <div className="p-4 flex flex-col gap-2">
          <button type="submit" className='btn btn-primary' disabled={loading} style={{ height: '3rem' }}>Reset password</button>
        </div>
      </form>
    </section>
  );
}
