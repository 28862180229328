import { gql } from "@apollo/client";


export const MUTATION_SEND_OTP = gql`
  mutation sendOTP($deviceId: String!) {
    sendOTP(deviceId: $deviceId)
  }
`;

export const MUTATION_VERIFY_OTP = gql`
  mutation verifyOTP($otp: String!) {
    verifyOTP(otp: $otp) {
      lastLogin
    }
  }
`;

export const MUTATION_UPDATE_USER = gql`
  mutation updateUser($firstName: String, $lastName: String, $phone: String) {
    updateUser(data: { firstName: $firstName, lastName: $lastName,  phone: $phone }) {
      firstName
      lastName
      phone
    }
  }
`;
