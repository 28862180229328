import { useMutation } from '@apollo/client';
import { FormEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as SvgLogo } from '../../../assets/img/common/logo.svg';
import { AppRoute } from '../../../routes';
import { GlobalErrorHandler } from '../../../components/error/global_error.component';
import { MUTATION_SEND_OTP, MUTATION_VERIFY_OTP } from '../../../graphql/mutations/user';
import { set } from 'date-fns';

export function OtpPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [infoMessage, setInfoMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [verifyOtp, { loading, error, data }] = useMutation(MUTATION_VERIFY_OTP, { errorPolicy: 'all' });
  const [sendOtp, { loading: sendOtpLoading, error: sendOtpError, data: sendOtpData }] = useMutation(MUTATION_SEND_OTP, { errorPolicy: 'all' });
  const [deviceId, setDeviceId] = useState<string>('');



  async function resendLink() {
    setInfoMessage('Resending OTP. Please wait...');
    setErrorMessage('');
    await sendOtp({variables: { deviceId }});
    setInfoMessage('OTP sent successfully');
    setErrorMessage('');
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    setDeviceId(id || '');
  }, []);

  async function onSubmit(e: FormEvent) {
    e.preventDefault();
    setInfoMessage('Verifying OTP. Please wait...');
    setErrorMessage('');
    const { data } = await verifyOtp({ variables: { otp: verificationCode } });
    if (data) {
      setInfoMessage('OTP verified successfully');
      setErrorMessage('');
      navigate(AppRoute.Home);
    } else {
      setErrorMessage('OTP verification failed');
      setInfoMessage('');
    }
  }

  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
      if (error.message === 'Device not found') {
        navigate(AppRoute.AccountLogin);
      }
    }
    if (sendOtpError) {
      setErrorMessage(sendOtpError.message);
    }
  }, [error, sendOtpError]);

  return (
    <section className="content account flex flex-col">
      <GlobalErrorHandler />
      <SvgLogo className='max-w-52 -mt-16 mx-auto text-center' />
      <h1 className='font-sans text-deep-blue font-bold leading-normal text-5xl tracking-wide mt-24 mb-4'>Verify Your Email</h1>
      <p className='text-center mb-8'>
        To enhance the security of your account, we need to verify your email address.
        Please enter the One-Time Password (OTP) that we've just sent to your email.
        Please check your inbox and, if necessary, your spam or junk folder.
      </p>
      <form className='card bg-white rounded-lg shadow-md mx-auto max-w-md p-8 w-1/2' onSubmit={onSubmit}>
        {infoMessage && <div className="mb-12 px-12 py-6 border rounded-lg bg-light-grey">{infoMessage}</div>}
        {errorMessage && <div className="mb-12 px-12 py-6 border rounded-lg bg-light-grey text-red-500 -600">{errorMessage}</div>}
        <div className="p-4 flex flex-col gap-2">
          <label className='text-base font-bold mb-2 tracking-normal -mt-2'>Verification code</label>
          <input
            className='box-border p-4 border border-gray-300 rounded w-full text-base'
            autoFocus
            type="text"
            value={verificationCode}
            placeholder='Enter your verification code'
            onChange={e => setVerificationCode(e.target.value)}
          />
        </div>
        <div className="p-4 flex flex-wrap justify-center gap-2">
          
          <button type="submit" className='btn btn-primary' style={{ height: '3rem' }}>Verify</button>
          <button className='btn btn-secondary' type="button" onClick={resendLink} style={{ height: '3rem' }}>Resend link</button>
        </div>
      </form>
    </section>
  );
}
