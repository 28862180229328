import React from "react";

interface FinancialDataProps {
  propertyPrice: string;
  transactionCosts: string;
  platformFee: string;
  investmentCost: string;
  annualGrossRent: string;
  serviceCharges: string;
  maintainCharges: string;
  annualNetIncome: string;
}

const Financials: React.FC<{ financialData: FinancialDataProps }> = ({
  financialData,
}) => {
  return (
    <div className="bg-[#1a2b46] text-[#f8f8f8] p-6 rounded-2xl mt-5">
      <div className="flex justify-between items-center mb-3">
        <h3 className="text-[#f8f8f8] font-bold text-[22px] tracking-[-0.48px]">
          Property Financials
        </h3>
        <div className="flex items-center border border-[#ffd000] bg-[rgba(255,208,0,0.12)] rounded-full text-[#ffd000] px-3 py-1 lg:px-4 lg:py-2 text-sm lg:text-base font-semibold tracking-[-0.32px]">
          <span className="w-2 h-2 bg-[#ffd000] rounded-full mr-2"></span>
          Estimation of 1 Year
        </div>
      </div>

      <div className="flex lg:flex-row flex-col lg:justify-between justify-center gap-4 lg:gap-8">
        <div className="flex-1 px-5">
          <h3 className="text-[#f8f8f8] text-base font-semibold tracking-[-0.36px] opacity-60 py-1.5 mb-4">
            Property Cost
          </h3>
          <div className="border-t border-[#3b4a67] my-4"></div>
          <div className="mb-5 flex justify-between text-base font-medium tracking-[-0.36px]">
            <span>Property Price</span>
            <span className="text-right opacity-70">
              {financialData.propertyPrice}
            </span>
          </div>
          <div className="mb-5 flex justify-between text-base font-medium tracking-[-0.36px]">
            <span>Transaction Costs</span>
            <span className="text-right opacity-70">
              {financialData.transactionCosts}
            </span>
          </div>
          <div className="mb-5 flex justify-between text-base font-medium tracking-[-0.36px]">
            <span>PropNerd Fee</span>
            <span className="text-right opacity-70">
              {financialData.platformFee}
            </span>
          </div>
          <div className="border-t border-[#3b4a67] my-4"></div>
          <div className="mb-5 flex justify-between text-base font-semibold tracking-[-0.36px]">
            <span>Investment Cost</span>
            <span className="text-right opacity-70">
              {financialData.investmentCost}
            </span>
          </div>
        </div>

        <div className="hidden lg:block border-l border-[rgba(177,177,177,0.24)] h-[240px] transform translate-x-[-50%]"></div>

        <div className="flex-1 px-5">
          <h3 className="text-[#f8f8f8] text-base font-semibold tracking-[-0.36px] opacity-60 py-1.5 mb-4">
            Rental income (Year 1)
          </h3>
          <div className="border-t border-[#3b4a67] my-4"></div>
          <div className="mb-5 flex justify-between text-base font-medium tracking-[-0.36px]">
            <span>Annual gross rent</span>
            <span className="text-right opacity-70">
              {financialData.annualGrossRent}
            </span>
          </div>
          <div className="mb-5 flex justify-between text-base font-medium tracking-[-0.36px]">
            <span>Service Charges</span>
            <span className="text-right opacity-70">
              ({financialData.serviceCharges})
            </span>
          </div>
          <div className="mb-5 flex justify-between text-base font-medium tracking-[-0.36px]">
            <span>Mgmt / Maint.</span>
            <span className="text-right opacity-70">
              ({financialData.maintainCharges})
            </span>
          </div>
          <div className="border-t border-[#3b4a67] my-4"></div>
          <div className="mb-5 flex justify-between text-base font-semibold tracking-[-0.36px]">
            <span>Annual net income</span>
            <span className="text-right opacity-70">
              {financialData.annualNetIncome}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Financials;
