import { useLazyQuery, useMutation } from '@apollo/client';
import { useState, useEffect, FormEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as SvgLogo } from '../../../assets/img/common/logo.svg';
import { GlobalErrorHandler } from '../../../components/error/global_error.component';
import { QUERY_EMAIL_EXISTS } from '../../../graphql/queries/get-email';
import { MUTATION_ADD_PASSWORD_RESET } from '../../../graphql/mutations/password-reset';



// Define the shape of the data returned by the QUERY_EMAIL_EXISTS query
interface EmailExistsData {
  userExists: boolean;
}

// Define the shape of the variables for the GET_USER_BY_EMAIL query
interface UserByEmailVars {
  email: string;
}

// Define the shape of the variables for the MUTATION_ADD_PASSWORD_RESET mutation
interface AddPasswordResetVars {
  email: string;
}

export function AccountForgetPage() {
  const location = useLocation();
  const [infoMessage, setInfoMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isFormVisible, setIsFormVisible] = useState<boolean>(true);

  const [checkEmailExists, { loading: checkingEmail , error: checkEmailError }] = useLazyQuery<EmailExistsData, UserByEmailVars>(QUERY_EMAIL_EXISTS, {
    onCompleted: data => {
      if (data.userExists) {
        processPasswordReset(email);
      } else {
        setInfoMessage('');
        setErrorMessage('No account registered with this email address.');
        setIsFormVisible(true); // Show form again on error
      }
    },
    onError: error => {
      setErrorMessage("Error checking email: " + error.message);
      setInfoMessage('');
      setIsFormVisible(true); // Show form again on error
    }
  });


  const [addPasswordReset, { loading: addingReset , data: resetData}] = useMutation<void, AddPasswordResetVars>(MUTATION_ADD_PASSWORD_RESET, {
    onCompleted: () => {
      setInfoMessage("Password reset request processed. Please check your email for the reset link.");
      setErrorMessage('');
      setIsFormVisible(false); // Hide form on success
    },
    onError: (error) => {
      setErrorMessage("Failed to process password reset request: " + error.message);
      setInfoMessage('');
      setIsFormVisible(true); // Show form again on error
    }
  });


  const processPasswordReset = (email: string) => {
    addPasswordReset({
      variables: { email }
    });
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (email) {
      setInfoMessage('Checking email registration...');
      setErrorMessage('');
      setIsFormVisible(false); // Hide form while processing
      checkEmailExists({ variables: { email } }); // Trigger the lazy query to check if the email exists
    }
  };

  return (
    <section className="  content account flex flex-col">
      <GlobalErrorHandler />
      <SvgLogo className='max-w-52 -mt-16 mx-auto text-center' />
      <h1 className='font-sans text-deep-blue font-bold leading-normal text-5xl tracking-wide mt-24 mb-4'>Reset your password</h1>
      <p className='font-sans text-lg text-deep-blue leading-relaxed font-normal tracking-tight max-w-screen-md mx-auto text-center -mt-2 mb-10'>Enter your email to receive a verification code and password reset link.</p>
      {isFormVisible && (
        <form className='card bg-white rounded-lg shadow-md mx-auto max-w-md p-8 w-1/2' onSubmit={onSubmit}>
          {infoMessage && <div className="mb-12 px-12 py-6 border rounded-lg bg-light-grey">{infoMessage}</div>}
          {errorMessage && <div className="mb-12 px-12 py-6 border rounded-lg bg-light-grey text-red-500">{errorMessage}</div>}
          <div className="p-4 flex flex-col gap-2">
            <label className='text-base font-bold mb-2 tracking-normal -mt-2'>Email</label>
            <input className='box-border p-4 border border-gray-300 rounded w-full text-base' autoFocus type="email" value={email} placeholder='Enter your email address' onChange={e => setEmail(e.target.value)} />
          </div>
          <div className="p-4 flex flex-col gap-2">

            <button type="submit" className='btn btn-primary h-12' disabled={checkingEmail || addingReset}>Reset Password</button>
          </div>
        </form>
      )}
      {!isFormVisible && (infoMessage || errorMessage) && (
        <div className='card bg-white rounded-lg shadow-md mx-auto min-w-fit max-w-md p-8 w-1/2'>
          {infoMessage && <div className=" mb-12 px-12 py-6 border rounded-lg bg-light-grey">{infoMessage}</div>}
          {errorMessage && <div className="  mb-12 px-12 py-6 border rounded-lg bg-light-grey text-red-500">{errorMessage}</div>}
        </div>
      )}
    </section>
  );
}
