import { useLazyQuery, useMutation } from "@apollo/client";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import { useLocation, useNavigate } from "react-router-dom";
import { QUERY_GET_PROPERTY } from "../../graphql/queries/get-property";
import { QUERY_IS_PROPERTY_BOOKMARKED } from "../../graphql/queries/property-bookmark";
import { AuthService } from "../../services/auth.service";

import {
  hide as hideIntercom
} from "@intercom/messenger-js-sdk";
import {
  Property,
  PropertyPledge,
  PropertyPledgeStatus
} from "../../__generated__/graphql";
import { GlobalErrorHandler } from "../../components/error/global_error.component";
import { Sidebar } from "../../components/sidebar/sidebar";
import PropertySkeleton from "../../components/skeleton/propertyskeleton";
import { MUTATION_PLEDGE_TO_PROPERTY } from "../../graphql/mutations/pledge";
import {
  MUTATION_ADD_PROPERTY_BOOKMARK,
  MUTATION_REMOVE_PROPERTY_BOOKMARK,
} from "../../graphql/mutations/property-bookmark";
import {
  toCurrencyDisplay,
  toTokens
} from "../../utils/currency.util";
import { getPropertyDocumentUrl } from "../../utils/document.util";
import { getPropertyImageUrl } from "../../utils/image.utils";
import { useAuthAndErrorHandling } from "../../utils/invalid-token.util";

import Zoom from "react-medium-image-zoom";
import LeftArrow from "../../assets/svgs/arrow-left.svg";
import RightArrow from "../../assets/svgs/arrow-right.svg";
import BathIcon from "../../assets/svgs/bath1.svg";
import BedIcon from "../../assets/svgs/bed1.svg";
import Ellipse1 from "../../assets/svgs/ellipse-1.svg";
import Ellipse2 from "../../assets/svgs/ellipse-2.svg";
import Ellipse3 from "../../assets/svgs/ellipse-3.svg";
import Ellipse from "../../assets/svgs/ellipse.svg";
import MapPointer from "../../assets/svgs/map.svg";
import RoiArrowUpIcon from "../../assets/svgs/roi-arrow.svg";
import { CalculatorChart } from "../../components/property/calculator-chart";
import SmallImages from "../../components/property/small-images";
import TabComponent from "../../components/property/tab-component";
import { AppRoute } from "../../routes";

const libraries = ["places", "marker"];

export function PropertyDetailsPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [investmentAmount, setInvestmentAmount] = useState(50000);
  const [property, setProperty] = useState<Property | null>(null);

  const [getProperty, { data, loading, error }] = useLazyQuery<{
    property: Property;
  }>(QUERY_GET_PROPERTY, { errorPolicy: "all" });
  const [
    pledgeToProperty,
    { data: pledgeData, loading: pledgeLoading, error: pledgeError },
  ] = useMutation(MUTATION_PLEDGE_TO_PROPERTY, { errorPolicy: "all" });
  const [
    checkBookmark,
    { data: bookMarkData, loading: bookmarkLoading, error: bookmarkError },
  ] = useLazyQuery<{ isPropertyBookmarked: boolean }>(
    QUERY_IS_PROPERTY_BOOKMARKED,
    {
      onCompleted: (bookMarkData) => {
        setIsBookmarked(bookMarkData.isPropertyBookmarked);
      },
    }
  );

  const [
    addBookmark,
    {
      data: bookMarkAddData,
      loading: bookmarkAddLoading,
      error: bookmarkAddError,
    },
  ] = useMutation(MUTATION_ADD_PROPERTY_BOOKMARK);
  const [
    removeBookmark,
    {
      data: bookMarkRemoveData,
      loading: bookmarkRemoveLoading,
      error: bookmarkRemoveError,
    },
  ] = useMutation(MUTATION_REMOVE_PROPERTY_BOOKMARK);
  const [cartAmount, setCartAmount] = useState(1);
  const [cartErrorMessage, setCartErrorMessage] = useState<string>();
  const [images, setImages] = useState<
    { id: string; ext: string; order: number }[]
  >([]);
  const [selectedImage, setSelectedImage] = useState(
    data?.property?.images ? data.property.images[0] : null
  );
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loadingImages, setLoadingImages] = useState(
    Array(images.length).fill(true)
  );

  const handleImageSelect = (image: any) => {
    setSelectedImage(image);
  };
  const [transitioning, setTransitioning] = useState(false);
  const smallImagesRef = useRef<HTMLDivElement>(null);
  const { isLoaded: isGoogleApiLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
    libraries: libraries as any,
  });
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  const [marker, setMarker] =
    useState<google.maps.marker.AdvancedMarkerElement>();

  const map = useRef<google.maps.Map | null>(null);

  useAuthAndErrorHandling(error);
  useAuthAndErrorHandling(bookmarkError);

  useEffect(() => {
    if (!AuthService.authenticate(navigate)) {
      return;
    }
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
    getProperty({ variables: { id } });
    checkBookmark({ variables: { propertyId: id } });

    const screenWidth = window.innerWidth;
    const isLargeScreen = screenWidth >= 1024;

    if (isLargeScreen) {
      // showIntercom();
    }

    return () => {
      if (isLargeScreen) {
        // hideIntercom();
      }
    };
  }, []);

  useEffect(() => {
    if (data && data.property) {
      setImages(sortImages(data.property.images as any));
      setLoadingImages(Array(data.property?.images?.length).fill(true));
    }
  }, [data]);

  const handleImageTransition = (direction: "next" | "prev") => {
    if (transitioning) return;

    setTransitioning(true);
    const newIndex =
      direction === "next"
        ? (currentImageIndex + 1) % images.length
        : (currentImageIndex - 1 + images.length) % images.length;

    // setSelectedImage(null); // This will trigger the fade-out

    setTimeout(() => {
      setCurrentImageIndex(newIndex);
      setSelectedImage(images[newIndex] as any);

      // Scroll the thumbnail into view
      if (smallImagesRef.current) {
        const thumbnails =
          smallImagesRef.current.getElementsByClassName("small-image");
        if (thumbnails[newIndex]) {
          thumbnails[newIndex].scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
        }
      }

      setTimeout(() => setTransitioning(false), 500); // Match this with your CSS transition time
    }, 250); // Half of the fade-out time
  };

  const handleNextImage = () => handleImageTransition("next");
  const handlePrevImage = () => handleImageTransition("prev");

  useEffect(() => {
    setSelectedImage(images[currentImageIndex] as any);
  }, [currentImageIndex, images]);

  useEffect(() => {
    if (!AuthService.authenticate(navigate)) {
      return;
    }
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
    getProperty({ variables: { id } });
    checkBookmark({ variables: { propertyId: id } });

    const screenWidth = window.innerWidth;
    const isLargeScreen = screenWidth >= 1024; // Adjust the value based on your needs, e.g., 1024px for tablets and larger screens

    if (isLargeScreen) {
      // showIntercom();
    }

    return () => {
      if (isLargeScreen) {
        hideIntercom();
      }
    };
  }, []);

  function addToCart() {
    if (data && data.property) {
      pledgeToProperty({
        variables: {
          propertyId: data.property.id,
          amount: cartAmount * 100 * 100,
        },
      });
    }
  }



  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = e.currentTarget.value;
    setCartAmount(parseInt(amount));
    onAmountChange(amount);
  };

  const incrementAmount = () => {
    const newAmount = cartAmount + 1;
    setCartAmount(newAmount);
    onAmountChange(newAmount.toString());
  };

  const decrementAmount = () => {
    if (cartAmount > 1) {
      const newAmount = cartAmount - 1;
      setCartAmount(newAmount);
      onAmountChange(newAmount.toString());
    }
  };


  function onAmountChange(amountStr: string) {
    if (!data || !data.property) {
      return;
    }
    setCartErrorMessage(undefined);
    const amount = parseInt(amountStr);
    if (!amount) {
      setCartAmount(0);
      setCartErrorMessage("Minimum tokens you can invest: 1");
      return;
    }
    if (amount > data.property.price || amount > data.property.agreementPrice) {
      setCartErrorMessage("Amount cannot exceed property price");
    }
    if (amount < 1) {
      setCartErrorMessage("Minimum tokens you can invest: 1");
    }
    setCartAmount(amount);
  }
  const fundedPercentage = (property: any) => {
    const confirmedPledges = property.pledges.filter(
      (pledge: any) => pledge.status === PropertyPledgeStatus.Confirmed
    );
    const totalConfirmedAmount = confirmedPledges.reduce(
      (sum: any, pledge: any) => sum + pledge.amount,
      0
    );
    return ((totalConfirmedAmount / property.price) * 100).toFixed(2);
  };

  async function handleBookmark() {
    const queryParams = new URLSearchParams(location.search);
    const propertyId = queryParams.get("id");

    if (isBookmarked) {
      await removeBookmark({ variables: { propertyId } });
    } else {
      await addBookmark({ variables: { propertyId } });
    }
    setIsBookmarked(!isBookmarked);
  }

  useEffect(() => {
    if (pledgeData && pledgeData.pledgeAmountToProperty) {
      navigate(AppRoute.Cart);
    }
  }, [pledgeData]);

  useEffect(() => {
    if (data && data.property && mapLoaded) {
      applyMarker();
    }

    if (data && data.property) {
      setImages(sortImages(data.property.images as any));
    }
  }, [data, mapLoaded]);

  function applyMarker() {
    if (
      data &&
      data.property &&
      data.property.location &&
      data.property.location.coordinates
    ) {
      setMarker(
        new google.maps.marker.AdvancedMarkerElement({
          position: new google.maps.LatLng(
            data.property.location.coordinates[1],
            data.property.location.coordinates[0]
          ),
          map: map.current,
          content: createMarkerContent(data.property),
        })
      );
    }
  }

  function onMapLoad(_map: google.maps.Map) {
    setMapLoaded(true);
    map.current = _map;

    if (
      mapLoaded &&
      data &&
      data.property &&
      data.property.location &&
      data.property.location.coordinates
    ) {
      applyMarker();
    }
  }

  const createMarkerContent = (property: any) => {
    const markerContent = document.createElement("div");
    const root = createRoot(markerContent);
    root.render(<MarkerContent {...property} />);
    return markerContent;
  };

  const financialData = {
    propertyPrice: toCurrencyDisplay(
      data?.property.price || data?.property.agreementPrice
    ),
    transactionCosts: toCurrencyDisplay(data?.property.transactionFee),
    platformFee: toCurrencyDisplay(data?.property.platformFee),
    investmentCost: toCurrencyDisplay(
      (data?.property.price || data?.property.agreementPrice) +
      data?.property.transactionFee +
      data?.property.platformFee
    ),
    annualGrossRent: toCurrencyDisplay(data?.property.rents[0]?.rent * 12),
    serviceCharges: toCurrencyDisplay(
      data?.property.financials[0]?.annualServiceCharges
    ),
    maintainCharges: toCurrencyDisplay(
      data?.property.financials[0]?.annualManagementAndMaintenanceCharges
    ),
    annualNetIncome: toCurrencyDisplay(
      data?.property.rents[0]?.rent * 12 -
      data?.property.financials[0]?.annualServiceCharges -
      data?.property.financials[0]?.annualManagementAndMaintenanceCharges
    ),
  };

  const documents =
    data?.property.documents && data?.property?.documents?.length > 0
      ? data?.property.documents?.map((doc: any) => ({
        id: doc.id,
        filename: doc.filename,
        url: getPropertyDocumentUrl(data.property.id, doc),
      }))
      : null;

  function sortImages(images: { id: string; ext: string; order: number }[]) {
    return images.slice().sort((a, b) => a.order - b.order);
  }

  const handleImageLoad = (index: number) => {
    const updatedLoadingImages = [...loadingImages];
    updatedLoadingImages[index] = false;
    setLoadingImages(updatedLoadingImages);
  };

  return (
    <>
      <GlobalErrorHandler />

      <Sidebar />

      {loading ? (
        <PropertySkeleton />
      ) : (
        data &&
        data.property && (


          <div className="content max-w-screen lg:max-w-screen-[1400px] property-details overflow-hidden mx-auto lg:ml-[270px]">

            <div className="mt-0 lg:mt-7">
              <div className="flex flex-col lg:flex-row justify-center lg:justify-between w-full">
                <h2 className="text-3xl lg:text-5xl font-bold text-black  leading-normal mb-[50px]">
                  {data.property.title}
                </h2>
                {/* Progress Bar will come here */}
                <div className="flex flex-col items-center gap-2 lg:px-0 px-2 ">
                  <div className="flex justify-between w-full lg:w-52">
                    <p className="text-sm font-semibold text-gray-800">
                      Funded
                    </p>
                    <p className="text-sm font-semibold text-gray-800">
                      {fundedPercentage(data.property)}%
                    </p>
                  </div>

                  <div className="bg-gray-200 rounded-full h-2.5 w-full lg:w-52">
                    <div
                      className="bg-yellow-400 h-2.5 rounded-full"
                      style={{ width: `${fundedPercentage(data.property)}%` }}
                    ></div>
                  </div>

                  <div className="actions mt-4 w-full lg:w-auto">
                    {bookMarkData && <div className={`btn w-full lg:w-auto ${isBookmarked ? 'py-6' : 'btn-secondary'} py-6`} onClick={() => handleBookmark()}>
                      <span className="lni lni-bookmark" /> {isBookmarked ? 'Bookmarked' : 'Bookmark'} </div>}
                  </div>
                </div>
              </div>
            </div>
            {images && !!images.length && (
              <div className="flex lg:flex-col py-2 px-1 gap-4">
                <div className="relative flex flex-col lg:flex-row gap-2 w-full">
                  <div className="relative w-full lg:w-8/12">
                    <div className="absolute top-5 right-6 flex items-center gap-2 py-1.5 px-2.5 bg-[#e6eaf4] rounded-full border border-[#253256] z-10  leading-normal">
                      <img src={Ellipse} alt="Ellipse" />
                      <span className="text-xs font-bold">
                        Tokens:{" "}
                        {toTokens(
                          data.property.price || data.property.agreementPrice
                        )}
                      </span>
                    </div>

                    <Zoom>
                      {loadingImages[currentImageIndex] && (
                        <div className="pulse-loader"></div>
                      )}
                      <img
                        className={`w-full h-[465px] lg:h-[630px] object-cover rounded-3xl transition-opacity duration-500 ease-in-out ${selectedImage ? "opacity-100" : "opacity-0"
                          }`}
                        src={getPropertyImageUrl(
                          data.property.id,
                          selectedImage ? selectedImage : (images[0] as any)
                        )}
                        alt={data.property.title}
                        onLoad={() => handleImageLoad(currentImageIndex)}
                      />
                    </Zoom>

                    <div className="gradient-overlay"></div>

                    <div className="absolute bottom-8 left-0 lg:left-2 text-white z-1 w-full">
                      <div className="flex flex-col justify-start items-center lg:flex-row lg:justify-between lg:items-center w-full gap-4 lg:gap-0 px-4 lg:px-8">
                        <div>
                          <h1 className="text-[26px] font-bold mb-1">
                            {data.property.title}
                          </h1>
                          <p className="flex items-center gap-2 text-sm">
                            <img
                              src={BedIcon}
                              alt="Bed Icon"
                              className="w-5 h-5 "
                            />{" "}
                            <span className="font-normal">
                              {data.property.bed} |
                            </span>
                            <img
                              src={BathIcon}
                              alt="Bath Icon"
                              className="w-5 h-5"
                            />{" "}
                            <span className="font-normal">
                              {data.property.bath} |
                            </span>
                            <span className="font-normal">
                              {data.property.sqFootage} sq.ft
                            </span>
                          </p>
                        </div>
                        <p className="">
                          <span className="block text-sm font-semibold tracking-tight">
                            Price
                          </span>
                          <span className="block text-[28px] font-bold tracking-tight leading-normal">
                            {toCurrencyDisplay(
                              data.property.price ||
                              data.property.agreementPrice
                            )}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="absolute top-[60%] bottom-[40%] inset-0 flex items-center justify-between">
                      <img
                        className="cursor-pointer opacity-100 transition-opacity duration-300 ease-in-out"
                        onClick={handlePrevImage}
                        src={LeftArrow}
                        alt="Left Arrow"
                      />
                      <img
                        className="cursor-pointer opacity-100 transition-opacity duration-300 ease-in-out"
                        onClick={handleNextImage}
                        src={RightArrow}
                        alt="Right Arrow"
                      />
                    </div>
                  </div>
                  <div className="detail-cards w-full lg:w-4/12">
                    {/* Card 1 */}
                    <div className="bg-[#ffd000] rounded-[16px] p-5 flex flex-col items-start gap-[10px] text-[#14223d] font-sans overflow-hidden">
                      <h3 className="font-bold text-[18px] font-['Plus Jakarta Sans'] tracking-[0.14px]">
                        About {data.property.city.name}
                      </h3>
                      <p className="font-medium text-[16px] font-['Plus Jakarta Sans'] leading-[22px] tracking-[-0.14px] max-h-[4.2em] whitespace-normal overflow-y-auto hidden-scrollbar">
                        {data.property.city.excerpt}
                      </p>
                      <div className="flex justify-between items-center bg-[#ffeb94] border border-[#14223d] rounded-[12px] p-3 w-full">
                        <div className="flex items-center gap-2">
                          <img
                            src={RoiArrowUpIcon}
                            alt="ROI Icon"
                            className="w-[24px] h-[24px]" /* Adjust as needed */
                          />
                          <span className="text-[#14223d] text-right text-[14px] font-bold leading-normal tracking-[0.12px] font-['Plus Jakarta Sans']">
                            Investors
                          </span>
                        </div>
                        <div className="text-[#14223d] text-right text-[14px] font-bold leading-normal tracking-[0.12px] font-['Plus Jakarta Sans']">
                          {data.property.pledges?.filter(
                            (pledge: PropertyPledge) =>
                              pledge.status === "Confirmed"
                          ).length || "0"}
                        </div>
                      </div>
                    </div>

                    {/* Card 2 */}
                    <div className="calculator-card flex items-center justify-center w-full rounded-2xl border border-[#ECECEC] bg-white py-5 px-2.5 mt-4 h-[410px]">
                      <CalculatorChart
                        investment={
                          (data.property.agreementPrice ||
                            data.property.price) / 100
                        }
                        valueAppreciation={data.property.financials[0].year3ProjectedAnnualRoIValue / 100}
                        annualIncome={(data.property.financials[0].annualRent / 100)}
                        years={3}
                        height={230}
                      />
                    </div>
                  </div>
                </div>

                <SmallImages
                  images={images}
                  propertyId={data.property.id}
                  onImageSelect={handleImageSelect}
                  selectedImage={selectedImage}
                  containerRef={smallImagesRef}
                />
              </div>
            )}
            <div>

              <TabComponent
                propertyDescription={data.property?.description}
                financialData={financialData}
                documents={documents}
                propertyId={data.property.id}
                initialCartAmount={cartAmount}
                onAddToCart={addToCart}
                cartErrorMessage={cartErrorMessage}
                onAmountChange={onAmountChange}
                pledgeLoading={pledgeLoading}
              />
            </div>
            <h2 className="text-black text-2xl font-bold leading-normal tracking-[-0.48px] my-4">
              Location
            </h2>
            <div className="map mb-32 lg:mb-0">
              {isGoogleApiLoaded &&
                data &&
                data.property &&
                data.property.city && (
                  <GoogleMap
                    ref={map as React.RefObject<GoogleMap>}
                    mapTypeId="roadmap"
                    mapContainerStyle={{
                      flex: 10,
                      height: 300,
                      width: "100%",
                      borderWidth: 1,
                    }}
                    center={
                      marker && marker.position
                        ? marker.position
                        : {
                          lat: data?.property.location.coordinates[1],
                          lng: data?.property.location.coordinates[0],
                        }
                    }
                    zoom={12}
                    options={{
                      mapId: "f93dc101cf6ad224",
                      disableDefaultUI: true,
                      zoomControl: true,
                    }}
                    onLoad={onMapLoad}
                  ></GoogleMap>
                )}
            </div>

            {/* Add to Cart Section */}
            <div className="bottom-1 lg:bottom-4 px-6 lg:px-2 pt-4 lg:pt-2 left-6 lg:left-auto lg:right-24 z-50 fixed flex flex-col lg:flex-row items-center self-end lg:items-center gap-2 lg:gap-8 bg-white p-2 rounded-lg shadow-lg">
              <div className="flex flex-col">
                {cartErrorMessage && (
                  <div className="text-red-500 py-6 text-end">{cartErrorMessage}</div>
                )}
                <div className="flex items-center bg-white px-4 py-2 rounded-lg border border-gray-200">
                  {/* Tokens Text */}
                  <div className="text-base text-[#14223D] flex items-center font-medium">
                    Tokens
                    <span className="text-[#E6BC2E] text-sm ml-1 font-medium">
                      ({toCurrencyDisplay(cartAmount! * 100 * 100)?.toString() || 0})
                    </span>
                  </div>

                  {/* Vertical Divider */}
                  <div className="h-6 border-l border-[rgba(177,177,177,0.24)] ml-4"></div>

                  {/* Number Input */}
                  <div className="flex items-center">
                    <input
                      type="number"
                      value={cartAmount}
                      onChange={handleAmountChange}
                      defaultValue={1}
                      min={1}
                      className="w-12 text-center text-base font-semibold bg-transparent border-none focus:outline-none appearance-none"
                    />
                    <div className="flex flex-col ml-1">
                      <button
                        className="text-gray-500 hover:text-black leading-none"
                        onClick={incrementAmount}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4.5l-4 4h8l-4-4z" />
                        </svg>
                      </button>
                      <button
                        className="text-gray-500 hover:text-black leading-none"
                        onClick={decrementAmount}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 11.5l4-4H4l4 4z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>

              </div>
              {/* Add to Cart Button */}
              <button
                className="bg-[#14223d] text-white font-semibold py-2 px-6 rounded-lg"
                disabled={pledgeLoading || !!cartErrorMessage}
                onClick={addToCart}
              >
                Add to Cart
              </button>

            </div>
          </div>
        )
      )}
    </>
  );
}

const MarkerContent = ({ id, title }: { id: string; title: string }) => {
  return (
    <div className="custom-marker">
      {/* Ellipses */}
      <img src={Ellipse1} className="ellipse ellipse-1" alt="Ellipse 1" />
      <img src={Ellipse2} className="ellipse ellipse-2" alt="Ellipse 2" />
      <img src={Ellipse3} className="ellipse ellipse-3" alt="Ellipse 3" />

      {/* Marker Icon */}
      <img src={MapPointer} className="marker-icon" alt="Marker Icon" />

      {/* Label */}
      <div className="marker-label">{title}</div>
    </div>
  );
};
