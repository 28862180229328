import { useMutation } from '@apollo/client';
import { FormEvent, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as SvgLogo } from '../../../assets/img/common/logo.svg';
import { MUTATION_LOGIN } from '../../../graphql/mutations/login';
import { AppRoute } from '../../../routes';
import { AuthService } from '../../../services/auth.service';
import { GlobalErrorHandler } from '../../../components/error/global_error.component';
import { MUTATION_SEND_OTP } from '../../../graphql/mutations/user';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { MUTATION_CHECK_AND_SAVE_DEVICE } from '../../../graphql/mutations/device';

export function AccountLoginPage() {

  const navigate = useNavigate();
  const location = useLocation();

  const [infoMessage, setInfoMessage] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [deviceId, setDeviceId] = useState<string>('');


  const [loginUser, { loading, error, data }] = useMutation(MUTATION_LOGIN, { errorPolicy: 'all' });
  const [sendOtp, { loading: sendOtpLoading, error: sendOtpError, data: sendOtpData }] = useMutation(MUTATION_SEND_OTP, { errorPolicy: 'all' });
  const [checkAndSaveDevice, { loading: checkDeviceLoading, error: checkDeviceError, data: checkDeviceData }] = useMutation(MUTATION_CHECK_AND_SAVE_DEVICE, { errorPolicy: 'all' });

  useEffect(() => {
    FingerprintJS.load().then(fp => {
      fp.get().then(result => {
        setDeviceId(result.visitorId);
      });
    });
  }, []);


  function getClassNameForRoute(route: AppRoute) {
    let className = 'flex align-middle items-center text-#686e7c text-base  border-radius';
    return location.pathname === route ? className + ' selected' : className;
  }

  useEffect(() => {
    if (data && data.login && data.login.accessToken) {
      AuthService.login(email, data.login.accessToken, data.login.lastLogin, deviceId);
      const userAgent = navigator.userAgent;
      const osVersion = getOSVersion(userAgent);
      const deviceType = getDeviceType(userAgent);
      const model = getModel(userAgent);

      checkAndSaveDevice({ variables: { deviceId, osVersion, deviceType, model } });
    }
    setInfoMessage(undefined);
  }, [data]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
    } else {
      setErrorMessage(undefined);
    }
  }, [error]);

  useEffect(() => {
    if (checkDeviceData) {
      // sendOtp({ variables: { deviceId } });
      // navigate(`${AppRoute.LoginVefitication}?id=${deviceId}`);
      navigate(AppRoute.Home);
    }

  }, [checkDeviceData]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const emailParam = queryParams.get('email');
    if (emailParam) {
      setEmail(emailParam);
    }
  }, [location]);

  async function onSubmit(e: FormEvent) {
    e.preventDefault();
    setInfoMessage('Logging in. Please wait...');
    await loginUser({ variables: { email, password } });
  }

  function getOSVersion(userAgent: string) {
    let osVersion = 'Unknown OS Version';

    if (/Windows NT 10.0/.test(userAgent)) osVersion = 'Windows 10';
    else if (/Windows NT 6.3/.test(userAgent)) osVersion = 'Windows 8.1';
    else if (/Windows NT 6.2/.test(userAgent)) osVersion = 'Windows 8';
    else if (/Windows NT 6.1/.test(userAgent)) osVersion = 'Windows 7';
    else if (/Mac OS X/.test(userAgent)) {
      const match = userAgent.match(/Mac OS X (\d+[\._]\d+[\._]?\d*)/);
      if (match) osVersion = `macOS ${match[1].replace(/_/g, '.')}`;
    } else if (/Android/.test(userAgent)) {
      const match = userAgent.match(/Android\s([0-9\.]*)/);
      if (match) osVersion = `Android ${match[1]}`;
    } else if (/iPhone OS/.test(userAgent)) {
      const match = userAgent.match(/iPhone OS (\d+[\._]\d+)/);
      if (match) osVersion = `iOS ${match[1].replace(/_/g, '.')}`;
    }

    return osVersion;
  }

  function getDeviceType(userAgent: string) {
    if (/android/i.test(userAgent)) return 'android';
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) return 'ios';
    return 'browser';
  }


  function getModel(userAgent: string) {
    let model = 'Unknown Model';

    if (/iPhone/.test(userAgent)) model = 'iPhone';
    else if (/iPad/.test(userAgent)) model = 'iPad';
    else if (/Macintosh/.test(userAgent)) model = 'Mac';
    else if (/Windows NT/.test(userAgent)) model = 'Windows PC';
    else if (/Android/.test(userAgent)) {
      const match = userAgent.match(/Android.*;\s([a-zA-Z0-9\s]+)\sBuild/);
      if (match) model = match[1];
    }

    return model;
  }


  return (
    <section className="content account flex flex-col ">
      <GlobalErrorHandler />
      <SvgLogo className='max-w-52 mt-0 lg:mt-16 mx-auto  text-center' />
      <h1 className='font-sans text-deep-blue  font-bold leading-normal text-4xl lg:text-5xl text-center  tracking-wide mt-24 mb-4'>Investor Login</h1>
      <p className=' font-sans text-lg text-deep-blue  leading-relaxed font-normal tracking-tight max-w-screen-md mx-auto text-center -mt-2 mb-10'>Welcome back to PropNerd! Your gateway to innovative real estate investment opportunities awaits. Sign in to access your personalized dashboard, manage your investments, and explore new properties ready for tokenization.</p>
      <form className='w-full card bg-white rounded-lg shadow-md  md:w-1/2  m-0  md:max-w-md py-8 px-4   md:p-8 ' onSubmit={onSubmit}>
        {infoMessage && <div className="mb-12  px-12  py-6  border rounded-lg bg-light-grey">{infoMessage}</div>}
        {errorMessage && <div className="mb-12  px-12  py-6  border rounded-lg bg-light-grey text-red-500 -600">{errorMessage}</div>}
        <div className="p-4 flex flex-col gap-2">
          <label className='text-base font-bold mb-2 tracking-normal -mt-2'>Email</label>
          <input className='box-border p-4 border border-gray-300 rounded w-full text-base' autoFocus type="email" value={email} placeholder='Enter your email address' onChange={e => setEmail(e.target.value)} />
        </div>
        <div className="p-4 flex flex-col gap-2">
          <label className='text-base font-bold mb-2 tracking-normal -mt-2'>Password</label>
          <input className='box-border p-4 border border-gray-300 rounded w-full text-base' type="password" value={password} placeholder='Enter your password' onChange={e => setPassword(e.target.value)} />
        </div>
        <div className="p-4 flex flex-col gap-2">
          <button type="submit" className='btn btn-primary' disabled={loading} style={{ height: '3rem' }}>Login</button>
        </div>
        <div className="flex justify-center text-center">
          <Link className={getClassNameForRoute(AppRoute.AccountForget)} to={AppRoute.AccountForget}>Forgot password?</Link>
        </div>
      </form>
    </section>
  )
}